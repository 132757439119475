// src/pages/Postes/SubComponents/EUIsDevices.js

import React from 'react';
import { FaPlus, FaTrash, FaSave } from 'react-icons/fa';
import styles from '../PosteModal.module.css';
import lumiIcon from '../../../assets/Lumi-icon.png'; // Caminho para o ícone

const EUIsDevices = ({
  euis,
  unsavedEUIs,
  handleAddEUI,
  handleEUIChange,
  handleSaveEUI,
  handleRemoveEUI,
  animateSaveButtons, // Recebe os botões a serem animados
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Dispositivos EUI</legend>

      {/* Dispositivos EUI Salvos */}
      {euis.length > 0 && (
        <div>
          <h4>Dispositivos EUI Salvos</h4>
          {euis.map((eui, index) => (
            <div key={eui.postefotocelulaId} className={styles.itemContainer}>
              <div className={styles.euiInputContainer}>
                <label className={styles.label}>
                  <img src={lumiIcon} alt="Lumi Icon" className={styles.icon} />
                  Device EUI
                </label>
                <input
                  className={styles.input}
                  type="text"
                  value={eui.deviceEUI}
                  readOnly
                />
              </div>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveEUI(eui)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Dispositivos EUI Não Salvos */}
      {unsavedEUIs.length > 0 && (
        <div>
          <h4>Novos Dispositivos EUI</h4>
          {unsavedEUIs.map((eui, index) => (
            <div key={index} className={styles.itemContainer}>
              <div className={styles.euiInputContainer}>
                <label className={styles.label}>
                  <img src={lumiIcon} alt="Lumi Icon" className={styles.icon} />
                  Device EUI
                </label>
                <input
                  className={styles.euiInputSmaller}
                  type="text"
                  value={eui}
                  onChange={(e) => handleEUIChange(index, e)}
                  required
                />
              </div>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={`${styles.saveButton} ${animateSaveButtons[index] ? styles.animateSaveButton : ''}`}
                  onClick={() => {
                    handleSaveEUI(index);
                  }}
                >
                  <FaSave />
                </button>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveEUI({ deviceEUI: eui, postefotocelulaId: null })}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <button
        type="button"
        className={styles.button}
        onClick={handleAddEUI}
      >
        <FaPlus /> Adicionar Dispositivo EUI
      </button>
    </fieldset>
  );
};

export default EUIsDevices;
