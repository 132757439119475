// src/components/MarkerModal/MarkerModal.js

import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './MarkerModal.module.css';
import placeholderImage from '../../assets/fotoGenericaDoPoste.png';
import {
  FaLightbulb,
  FaSyncAlt,
  FaCheckCircle,
  FaExclamationTriangle,
  FaClock,
  FaMapMarkerAlt,
  FaChevronDown,
  FaChevronUp,
  FaTimes,
  FaBatteryHalf,
  FaThermometerHalf,
  FaBolt,
  FaTachometerAlt,
} from 'react-icons/fa';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const MarkerModal = ({ isOpen, onClose, markerData }) => {
  const [isFullImageVisible, setIsFullImageVisible] = useState(false);
  const [expandedAlertId, setExpandedAlertId] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('potencia');
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0); // Dispositivo IoT selecionado
  const [tabIndex, setTabIndex] = useState(0); // Aba ativa

  // Resetar o estado ao abrir um novo modal para um poste diferente
  useEffect(() => {
    if (markerData && markerData.idPoste) {
      setSelectedDeviceIndex(0);
      setSelectedMetric('potencia');
      setTabIndex(0);
      console.log('MarkerData atualizado:', markerData);
    }
  }, [markerData]);

  if (!isOpen || !markerData) return null;

  const formattedId = markerData?.posteData?.codigoPoste || 'CEA000000001';
  const fotocelulas = markerData?.fotocelulas || []; // Fotocelulas ativas associadas ao poste
  const selectedDeviceData = fotocelulas[selectedDeviceIndex]; // Dispositivo IoT selecionado
  const selectedMetricsData = selectedDeviceData?.metrics || {}; // Métricas do dispositivo selecionado

  // Função para alternar a exibição do alerta expandido
  const toggleAlert = (id) => {
    setExpandedAlertId(expandedAlertId === id ? null : id);
  };

  // Mapeamento das situações dos alertas
  const statusMap = {
    '1': 'Aguardando',
    '2': 'Atendendo',
    '3': 'Cancelada',
    '4': 'Finalizada',
  };

  // Selecionar os 3 últimos alertas, ordenados por data e hora
  const sortedAlerts = markerData.alerts
    ? markerData.alerts.sort((a, b) => {
      const dateA = new Date(`${a.alertaDataGeracao}T${a.alertaHoraGeracao}`);
      const dateB = new Date(`${b.alertaDataGeracao}T${b.alertaHoraGeracao}`);
      return dateB - dateA;
    })
    : [];

  const latestAlerts = sortedAlerts.slice(0, 3);

  const getTensaoColor = (tensao) => {
    if (tensao >= 198 && tensao <= 242) {
      return 'green'; // Tensão Boa
    } else if (tensao > 242 && tensao <= 264) {
      return 'yellow'; // Tensão Média Alta
    } else if (tensao > 264) {
      return 'red'; // Tensão Alta
    } else if (tensao >= 176 && tensao < 198) {
      return 'lightblue'; // Tensão Média Baixa (Azul claro)
    } else if (tensao < 176) {
      return 'darkblue'; // Tensão Baixa (Azul escuro)
    }
    return 'black'; // Caso a tensão não esteja disponível ou inválida
  };

  return (
    <div className={styles.overlay}>
      <div
        className={`${styles.modalContentWrapper} ${isFullImageVisible ? styles.blurredBackground : ''
          }`}
      >
        <div className={styles.modal}>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>

          <h2 className={styles.title}>Informações do Poste {formattedId}</h2>

          <div className={styles.modalContentArea}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Informações Gerais</Tab>
                <Tab>Alertas</Tab>
                <Tab disabled={fotocelulas.length === 0}>Dispositivos IoT</Tab>
                <Tab disabled={fotocelulas.length === 0}>Métricas e Gráficos</Tab>
              </TabList>

              {/* Aba 1: Informações Gerais */}
              <TabPanel>
                <div className={styles.generalInfo}>
                  <p>
                    <FaMapMarkerAlt /> Endereço:{' '}
                    {markerData?.posteData?.address || 'Endereço não disponível'}
                  </p>

                  {/* Adicionando 'tensaoRede' aqui */}
                  <p
                    style={{ color: getTensaoColor(parseFloat(markerData?.tensaoRede)) }}
                  >
                    <FaBolt /> Tensão de Rede: {markerData?.tensaoRede || 'N/A'} V
                  </p>


                  <div className={styles.imageContainer}>
                    <img
                      src={placeholderImage}
                      alt="Poste"
                      className={styles.posteImage}
                      onClick={() => setIsFullImageVisible(true)}
                    />
                  </div>

                  {isFullImageVisible && (
                    <div
                      className={styles.fullImageOverlay}
                      onClick={() => setIsFullImageVisible(false)}
                    >
                      <img
                        src={placeholderImage}
                        alt="Poste em tamanho real"
                        className={styles.fullsizeImage}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>

              {/* Aba 2: Alertas */}
              <TabPanel>
                <div className={styles.alertSection}>
                  <h3>Últimos Alertas</h3>
                  {latestAlerts.length > 0 ? (
                    latestAlerts.map((alert) => {
                      const isResolved = ['3', '4'].includes(alert.alertaSituacao);
                      return (
                        <div
                          key={alert.alertaId}
                          className={`${styles.alertHeader} ${isResolved ? styles.resolvedAlert : styles.unresolvedAlert
                            }`}
                          onClick={() => toggleAlert(alert.alertaId)}
                        >
                          <div>
                            {isResolved ? (
                              <FaCheckCircle className={styles.resolvedIcon} />
                            ) : (
                              <FaExclamationTriangle className={styles.unresolvedIcon} />
                            )}
                            {alert.alertaMotivo}
                          </div>
                          {expandedAlertId === alert.alertaId ? (
                            <FaChevronUp className={styles.chevronIcon} />
                          ) : (
                            <FaChevronDown className={styles.chevronIcon} />
                          )}
                          {expandedAlertId === alert.alertaId && (
                            <div className={styles.alertDetails}>
                              <p>
                                <FaClock /> Data: {alert.alertaDataGeracao}{' '}
                                {alert.alertaHoraGeracao}
                              </p>
                              <p>
                                <FaMapMarkerAlt /> Origem: {alert.alertaOrigem}
                              </p>
                              <p>Motivo: {alert.alertaMotivo}</p>
                              <p>Situação: {statusMap[alert.alertaSituacao]}</p>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p>Nenhum alerta recente.</p>
                  )}
                </div>
              </TabPanel>

              {/* Aba 3: Dispositivos IoT */}
              <TabPanel>
                <div className={styles.deviceTab}>
                  {fotocelulas.length > 0 ? (
                    <>
                      <div className={styles.deviceIcons}>
                        {fotocelulas.map((device, index) => (
                          <div key={device.fotocelulaId} className={styles.deviceIconContainer}>
                            <FaLightbulb
                              className={
                                selectedDeviceIndex === index
                                  ? styles.activeDeviceIcon
                                  : styles.inactiveDeviceIcon
                              }
                              onClick={() => setSelectedDeviceIndex(index)}
                              title="Ativo"
                              style={{
                                color: 'green', // Todas as fotocelulas aqui são ativas
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        ))}
                      </div>

                      <div className={styles.deviceSection}>
                        <h3>Dados do Dispositivo</h3>
                        <p>
                          <FaLightbulb /> Descrição:{' '}
                          {selectedDeviceData?.descricao || 'Não disponível'}
                        </p>
                        <p>
                          <FaTachometerAlt /> Id do dispositivo:{' '}
                          {selectedDeviceData?.deviceEUI || 'Não disponível'}
                        </p>
                        <p>
                          <FaClock /> Última atualização:{' '}
                          {selectedDeviceData?.deviceAtualizacao || 'Não disponível'}
                        </p>
                        <p>
                          <FaThermometerHalf /> Potência:{' '}
                          {selectedDeviceData?.potencia || 'N/A'} W
                        </p>
                        <p>
                          <FaClock /> Tempo Ligada:{' '}
                          {selectedDeviceData?.tempolampadaligada || 'N/A'} h
                        </p>
                        <p>
                          <FaBatteryHalf /> Corrente:{' '}
                          {selectedDeviceData?.corrente || 'N/A'} A
                        </p>
                        <p
                          className={
                            parseFloat(markerData?.tensaoRede) > 220
                              ? styles.alertaTensao
                              : ''
                          }
                        >
                          <FaBolt /> Tensão: {markerData?.tensaoRede || 'N/A'} V
                        </p>
                        <p>
                          <FaTachometerAlt /> Fator de Potência:{' '}
                          {selectedDeviceData?.pf || 'N/A'}
                        </p>
                      </div>
                    </>
                  ) : (
                    <p>Nenhum dispositivo IoT neste poste.</p>
                  )}
                </div>
              </TabPanel>

              {/* Aba 4: Métricas e Gráficos */}
              <TabPanel>
                <div className={styles.metricsTab}>
                  {fotocelulas.length > 0 ? (
                    <>
                      <div className={styles.deviceSelection}>
                        <label htmlFor="deviceSelect">Dispositivo:</label>
                        <select
                          id="deviceSelect"
                          value={selectedDeviceIndex}
                          onChange={(e) =>
                            setSelectedDeviceIndex(Number(e.target.value))
                          }
                        >
                          {fotocelulas.map((device, index) => (
                            <option key={device.fotocelulaId} value={index}>
                              {device.descricao} - {device.deviceEUI}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={styles.metricSelection}>
                        <label htmlFor="metric">Métrica:</label>
                        <select
                          id="metric"
                          value={selectedMetric}
                          onChange={(e) => setSelectedMetric(e.target.value)}
                        >
                          <option value="tensao">Tensão</option>
                          <option value="potencia">Potência</option>
                          <option value="consumo">Consumo</option>
                          <option value="corrente">Corrente</option>
                          <option value="onOff">ON/OFF</option>
                        </select>
                      </div>

                      {selectedMetricsData && selectedMetricsData[selectedMetric] ? (
                        <>
                          <h4>
                            {selectedDeviceData?.descricao} -{' '}
                            {selectedDeviceData?.deviceEUI}
                          </h4>
                          <h4>
                            Leitura de{' '}
                            {selectedMetric.charAt(0).toUpperCase() +
                              selectedMetric.slice(1)}{' '}
                            - Últimos 7 Dias
                          </h4>
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={selectedMetricsData[selectedMetric]}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="day" />
                              <YAxis />
                              <Tooltip />
                              <Line
                                type="monotone"
                                dataKey="valor"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </>
                      ) : (
                        <p>Dados da métrica indisponíveis.</p>
                      )}
                    </>
                  ) : (
                    <p>Nenhum dispositivo IoT disponível para exibir métricas.</p>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <div className={styles.modalFooter}>
            <button className={styles.updateButton}>
              <FaSyncAlt /> Atualizar Dados
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkerModal;
