// src/config.js

// Versão do Aplicativo
export const APP_VERSION = "v00.03"; // Atualize este valor conforme a versão do package.json

// Localização Central do Mapa
export const MAP_CENTER = {
  lat: -8.072,    // Latitude central
  lng: -34.945,   // Longitude central
};

// Chave da API do Google Maps
// **Importante:** Armazene sua chave de API em variáveis de ambiente para maior segurança.
// No arquivo .env na raiz do projeto, adicione:
// REACT_APP_GOOGLE_MAPS_API_KEY=Sua_Chave_API_Aqui

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "YOUR_GOOGLE_MAPS_API_KEY";
