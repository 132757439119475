// src/hooks/useClassificacaoVia.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de classificações de via.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de classificações de via, estado de carregamento, erro e função para refetch.
 */
const useClassificacaoVia = (isOpen) => {
  const [classificacoesVia, setClassificacoesVia] = useState([]);
  const [isClassificacaoViaLoading, setIsClassificacaoViaLoading] = useState(false);
  const [classificacaoViaError, setClassificacaoViaError] = useState(null);

  /**
   * Função para buscar as classificações de via da API.
   */
  const fetchClassificacaoVia = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsClassificacaoViaLoading(true);
    setClassificacaoViaError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/classificacaovia', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar classificações de via: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Classificações de Via recebidas da API:', data); // Log para depuração
      setClassificacoesVia(data);
    } catch (error) {
      console.error('Erro ao buscar classificações de via:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setClassificacaoViaError('Você precisa estar autenticado para carregar a lista de classificações de via.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de classificações de via.');
        window.location.href = '/login';
      } else {
        setClassificacaoViaError('Falha ao carregar a lista de classificações de via. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsClassificacaoViaLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchClassificacaoVia();
  }, [fetchClassificacaoVia]);

  return { classificacoesVia, isClassificacaoViaLoading, classificacaoViaError, refetch: fetchClassificacaoVia };
};

export default useClassificacaoVia;
