// src/pages/Postes/SubComponents/Componentes.js

import React from 'react';
import { FaPlus, FaTrash, FaSave, FaCogs, FaLayerGroup, FaCalendar, FaClock } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const Componentes = ({
  grupos,
  isGruposLoading,
  gruposError,
  componentes,
  isComponentesLoading,
  componentesError,
  unsavedComponentes,
  handleAddComponent,
  handleComponentChange,
  handleSaveComponent,
  handleRemoveComponent,
  animateSaveButtons,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Componentes</legend>

      {/* Componentes Salvos */}
      {unsavedComponentes.length > 0 && (
        <div>
          <h4>Componentes Não Salvos</h4>
          {unsavedComponentes.map((componente, index) => (
            <div key={index} className={styles.itemContainer}>
              {/* Grupo */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Grupo</label>
                <select
                  className={styles.input}
                  name="grupoId"
                  value={componente.grupoId}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                >
                  <option value="">Selecione um grupo</option>
                  {isGruposLoading ? (
                    <option value="">Carregando grupos...</option>
                  ) : gruposError ? (
                    <option value="">Erro ao carregar grupos</option>
                  ) : (
                    grupos.map((grupo) => (
                      <option key={grupo.pgId} value={grupo.pgId}>
                        {grupo.pgNome}
                      </option>
                    ))
                  )}
                </select>
              </div>

              {/* Componente */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Componente</label>
                <select
                  className={styles.input}
                  name="componenteId"
                  value={componente.componenteId}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                  disabled={!componente.grupoId}
                >
                  <option value="">Selecione um componente</option>
                  {isComponentesLoading ? (
                    <option value="">Carregando componentes...</option>
                  ) : componentesError ? (
                    <option value="">Erro ao carregar componentes</option>
                  ) : (
                    componentes
                      .filter((comp) => comp.produtoGrupo === componente.grupoId)
                      .map((comp) => (
                        <option key={comp.produtoId} value={comp.produtoId}>
                          {comp.produtoDescricao}
                        </option>
                      ))
                  )}
                </select>
              </div>

              {/* Data */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCalendar /> Data</label>
                <input
                  className={styles.input}
                  type="text"
                  name="data"
                  value={componente.data}
                  readOnly
                />
              </div>

              {/* Hora */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaClock /> Hora</label>
                <input
                  className={styles.input}
                  type="text"
                  name="hora"
                  value={componente.hora}
                  readOnly
                />
              </div>

              {/* Botões de Ação */}
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={`${styles.saveButton} ${animateSaveButtons[index] ? styles.animateSaveButton : ''}`}
                  onClick={() => handleSaveComponent(index)}
                >
                  <FaSave />
                </button>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveComponent(componente)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Botão para Adicionar Novo Componente */}
      <button
        type="button"
        className={styles.button}
        onClick={handleAddComponent}
      >
        <FaPlus /> Adicionar Componente
      </button>
    </fieldset>
  );
};

export default Componentes;
