import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import alertLogs from '../../data/alertLogs.json';
import serviceOrders from '../../data/serviceOrders.json';
import deviceLogs from '../../data/logs.json';
import markers from '../../data/markers.json';
import groupsData from '../../data/groups.json';
import styles from './Levantamentos.module.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Levantamentos = () => {
  const [chartType, setChartType] = useState('');
  const [dataType, setDataType] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('all');
  const [selectedDispositivo, setSelectedDispositivo] = useState('all');
  const [selectedMetric, setSelectedMetric] = useState('tensaoRede');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [aggregateType, setAggregateType] = useState('average');

  useEffect(() => {
    if (selectedGroup) {
      setSelectedPoste('all');
      setSelectedDispositivo('all');
    }
  }, [selectedGroup]);

  const getPosteOptions = () => {
    const relevantMarkers = selectedGroup 
      ? markers.filter(marker => groupsData.find(group => group.id.toString() === selectedGroup).postes.includes(marker.idPoste))
      : markers;

    return relevantMarkers.map((poste) => ({
      value: poste.idPoste.toString(),
      label: `Poste ${poste.idPoste}`
    }));
  };

  const getGroupOptions = () => {
    return groupsData.map((group) => ({
      value: group.id.toString(),
      label: group.name
    }));
  };

  const getDispositivoOptions = () => {
    if (selectedPoste === 'all') return [];
    const poste = markers.find((poste) => poste.idPoste.toString() === selectedPoste);
    return poste ? poste.luminarias.map((luminaria) => ({
      value: luminaria.dispositivoEUI,
      label: `Dispositivo ${luminaria.dispositivoEUI}`
    })) : [];
  };

  const filterLogsByDate = (logs) => {
    return logs.filter(log => {
      const logDate = new Date(log.data);
      const startDateObj = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
      const endDateObj = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;
      return (!startDateObj || logDate >= startDateObj) && (!endDateObj || logDate <= endDateObj);
    });
  };

  const getFilteredData = () => {
    if (dataType === 'alerts') {
      const alertStatusCounts = alertLogs.reduce((acc, alert) => {
        acc[alert.status] = (acc[alert.status] || 0) + 1;
        return acc;
      }, {});

      return Object.keys(alertStatusCounts).map((key) => ({
        name: key,
        value: alertStatusCounts[key],
      }));
    } else if (dataType === 'orders') {
      const orderStatusCounts = serviceOrders.reduce((acc, order) => {
        acc[order.status] = (acc[order.status] || 0) + 1;
        return acc;
      }, {});

      return Object.keys(orderStatusCounts).map((key) => ({
        name: key,
        value: orderStatusCounts[key],
      }));
    } else if (dataType === 'devices') {
      const relevantMarkers = selectedGroup ? markers.filter(marker => groupsData.find(group => group.id.toString() === selectedGroup).postes.includes(marker.idPoste)) : markers;
      const markersToUse = selectedPoste !== 'all' ? [markers.find(marker => marker.idPoste.toString() === selectedPoste)] : relevantMarkers;

      const aggregateData = markersToUse.reduce((acc, poste) => {
        poste.luminarias.forEach((luminaria) => {
          const deviceLog = deviceLogs.find(device => device.dispositivoEUI === luminaria.dispositivoEUI);
          if (deviceLog) {
            const filteredLogs = filterLogsByDate(deviceLog.logs);
            filteredLogs.forEach((log) => {
              acc[log.data] = acc[log.data] || { count: 0, total: 0 };
              acc[log.data].count += 1;
              acc[log.data].total += log[selectedMetric];
            });
          }
        });
        return acc;
      }, {});

      const aggregatedData = Object.keys(aggregateData).map((key) => ({
        data: key,
        value: aggregateType === 'average' ? (aggregateData[key].total / aggregateData[key].count) : aggregateData[key].total
      }));

      return aggregatedData;
    }
  };

  const data = getFilteredData();

  return (
    <div className={styles.levantamentosPage}>
      <h1 className={styles.header}>Levantamentos</h1>
      <div className={styles.controls}>
        <div className={styles.controlGroup}>
          <label htmlFor="dataType">Tipo de Dados:</label>
          <select id="dataType" value={dataType} onChange={(e) => {
            setDataType(e.target.value);
            setChartType(''); // Reset chart type on data type change
          }}>
            <option value="">Selecione o tipo de dados</option>
            <option value="alerts">Alertas</option>
            <option value="orders">Ordens de Serviço</option>
            <option value="devices">Logs de Dispositivos</option>
          </select>
        </div>
        {dataType && (
          <div className={styles.controlGroup}>
            <label htmlFor="chartType">Tipo de Gráfico:</label>
            <select id="chartType" value={chartType} onChange={(e) => setChartType(e.target.value)}>
              <option value="">Selecione o tipo de gráfico</option>
              {dataType !== 'devices' && (
                <>
                  <option value="pie">Gráfico de Pizza</option>
                  <option value="bar">Gráfico de Barras</option>
                </>
              )}
              {dataType === 'devices' && (
                <option value="line">Gráfico de Linhas</option>
              )}
            </select>
          </div>
        )}
        {dataType === 'devices' && (
          <>
            <div className={styles.controlGroup}>
              <label htmlFor="selectedGroup">Grupo:</label>
              <select id="selectedGroup" value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)}>
                <option value="">Selecione um grupo</option>
                {getGroupOptions().map((group) => (
                  <option key={group.value} value={group.value}>{group.label}</option>
                ))}
              </select>
            </div>
            <div className={styles.controlGroup}>
              <label htmlFor="poste">Poste:</label>
              <select id="poste" value={selectedPoste} onChange={(e) => setSelectedPoste(e.target.value)}>
                <option value="all">Todos</option>
                {getPosteOptions().map((poste) => (
                  <option key={poste.value} value={poste.value}>{poste.label}</option>
                ))}
              </select>
            </div>
            {(selectedPoste !== 'all' || selectedDispositivo === 'all') && (
              <div className={styles.controlGroup}>
                <label htmlFor="dispositivo">Dispositivo:</label>
                <select id="dispositivo" value={selectedDispositivo} onChange={(e) => setSelectedDispositivo(e.target.value)}>
                  <option value="all">Todos</option>
                  {getDispositivoOptions().map((dispositivo) => (
                    <option key={dispositivo.value} value={dispositivo.value}>{dispositivo.label}</option>
                  ))}
                </select>
              </div>
            )}
            {(selectedPoste === 'all' || selectedDispositivo === 'all') && (
              <div className={styles.controlGroup}>
                <label htmlFor="aggregateType">Tipo de Agregação:</label>
                <select id="aggregateType" value={aggregateType} onChange={(e) => setAggregateType(e.target.value)}>
                  <option value="average">Média</option>
                  <option value="sum">Soma</option>
                </select>
              </div>
            )}
            <div className={styles.controlGroup}>
              <label htmlFor="metric">Métrica:</label>
              <select id="metric" value={selectedMetric} onChange={(e) => setSelectedMetric(e.target.value)}>
                <option value="tensaoRede">Tensão</option>
                <option value="potenciaAtual">Potência</option>
                <option value="consumo">Consumo</option>
                <option value="corrente">Corrente</option>
                <option value="isLigado">ON/OFF</option>
              </select>
            </div>
          </>
        )}
        <div className={styles.controlGroup}>
          <label htmlFor="startDate">Data Inicial:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className={styles.controlGroup}>
          <label htmlFor="startTime">Hora Inicial:</label>
          <input
            type="time"
            id="startTime"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className={styles.controlGroup}>
          <label htmlFor="endDate">Data Final:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className={styles.controlGroup}>
          <label htmlFor="endTime">Hora Final:</label>
          <input
            type="time"
            id="endTime"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.chartContainer}>
        {!chartType && (
          <div className={styles.message}>
            Selecione o tipo de dados e o tipo de gráfico para visualizar as informações.
          </div>
        )}
        {chartType === 'pie' && (dataType === 'alerts' || dataType === 'orders') && (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend verticalAlign="top" />
            </PieChart>
          </ResponsiveContainer>
        )}
        {chartType === 'bar' && (dataType === 'alerts' || dataType === 'orders') && (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
              <Bar dataKey="value" fill="#82ca9d">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
        {chartType === 'line' && dataType === 'devices' && (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="data" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default Levantamentos;
