// src/hooks/useFormatTampo.js

import { useState, useEffect, useCallback } from 'react';

const useFormatTampo = (isOpen) => {
  const [formatosTampo, setFormatosTampo] = useState([]);
  const [isFormatTampoLoading, setIsFormatTampoLoading] = useState(false);
  const [formatoTampoError, setFormatoTampoError] = useState(null);

  const fetchFormatTampo = useCallback(async () => {
    if (!isOpen) return;

    setIsFormatTampoLoading(true);
    setFormatoTampoError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/formatotampa', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar formatos de tampo: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Formatos de Tampo recebidos da API:', data);
      setFormatosTampo(data);
    } catch (error) {
      console.error('Erro ao buscar formatos de tampo:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setFormatoTampoError('Você precisa estar autenticado para carregar a lista de formatos de tampo.');
        alert('Você precisa estar autenticado para carregar a lista de formatos de tampo.');
        window.location.href = '/login';
      } else {
        setFormatoTampoError('Falha ao carregar a lista de formatos de tampo. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsFormatTampoLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchFormatTampo();
  }, [fetchFormatTampo]);

  return { formatosTampo, isFormatTampoLoading, formatoTampoError, refetch: fetchFormatTampo };
};

export default useFormatTampo;
