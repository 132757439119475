import React, { useState } from 'react';
import styles from './Legend.module.css';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';

const Legend = ({ markerCounts, toggleMarkerVisibility, visibleMarkers }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  // Garantir que markerCounts tenha valores seguros
  const safeMarkerCounts = markerCounts || {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
  };

  const safeVisibleMarkers = visibleMarkers || {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={styles.legendContainer}>
      <h3 className={styles.legendTitle}>Legenda</h3>
      {!isMinimized && (
        <ul className={styles.legendList}>
          <li
            onClick={() => toggleMarkerVisibility('acesa')}
            className={!safeVisibleMarkers.acesa ? styles.inactive : ''}
          >
            <img src={acesaIcon} alt="Acesa" className={styles.icon} />
            <span>Acesa ({safeMarkerCounts.acesa})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('apagada')}
            className={!safeVisibleMarkers.apagada ? styles.inactive : ''}
          >
            <img src={apagadaIcon} alt="Apagada" className={styles.icon} />
            <span>Apagada ({safeMarkerCounts.apagada})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('semFotocelula')}
            className={!safeVisibleMarkers.semFotocelula ? styles.inactive : ''}
          >
            <img src={semFotocelulaIcon} alt="Sem Fotocélula" className={styles.icon} />
            <span>Sem Fotocélula ({safeMarkerCounts.semFotocelula})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('alerta')}
            className={!safeVisibleMarkers.alerta ? styles.inactive : ''}
          >
            <img src={alertaIcon} alt="Alerta" className={styles.icon} />
            <span>Alerta ({safeMarkerCounts.alerta})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('subTensao')}
            className={!safeVisibleMarkers.subTensao ? styles.inactive : ''}
          >
            <img src={subtensaoIcon} alt="Alerta de SubTensão" className={styles.icon} />
            <span>Alerta de SubTensão ({safeMarkerCounts.subTensao})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('sobreTensao')}
            className={!safeVisibleMarkers.sobreTensao ? styles.inactive : ''}
          >
            <img src={sobretensaoIcon} alt="Alerta de SobreTensão" className={styles.icon} />
            <span>Alerta de SobreTensão ({safeMarkerCounts.sobreTensao})</span>
          </li>
        </ul>
      )}
      <button className={styles.minimizeButton} onClick={toggleMinimize}>
        {isMinimized ? 'Expandir' : 'Minimizar'}
      </button>
    </div>
  );
};

// Definindo valores padrão para evitar problemas com `undefined`
Legend.defaultProps = {
  markerCounts: {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
  },
  toggleMarkerVisibility: () => {},
  visibleMarkers: {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
  },
};

export default Legend;
