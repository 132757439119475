import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import styles from './GruposPostes.module.css';
import Legend from '../Mapa/Legend';
import SatelliteToggleButton from '../Mapa/SatelliteToggleButton';
import markersData from '../../data/markers.json';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import semLuminariaIcon from '../../assets/grey-icon2.png';
import selectedIcon from '../../assets/selected-marker.png'; // Novo ícone para o marcador selecionado
import { FaSearch, FaTrash, FaCog, FaEdit } from 'react-icons/fa';
import alertLogs from '../../data/alertLogs.json';
import { lightMapStyles } from '../../config/mapStyles'; // Importar apenas o estilo claro
import groupsData from '../../data/groups.json'; // Carregar grupos salvos
import PosteConfigurationModal from '../../components/PosteConfigurationModal/PosteConfigurationModal'; // Importar o modal de configuração de postes

const containerStyle = {
  width: '100%',
  height: '100vh'
};

const center = {
  lat: -8.072,
  lng: -34.945
};

const GruposPostes = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCV2s1z6eBC30NNKRUvsihZdsKAe6TRFlQ',
  });

  const mapRef = useRef(null);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [markerCounts, setMarkerCounts] = useState({
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    semLuminaria: 0,
    alerta: 0,
    offline: 0,
    falha: 0,
    subTensao: 0,
    sobreTensao: 0
  });
  const [visibleMarkers, setVisibleMarkers] = useState({
    acesa: true,
    apagada: true,
    semFotocelula: true,
    semLuminaria: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true
  });
  const [searchInput, setSearchInput] = useState('');
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [group, setGroup] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groups, setGroups] = useState(groupsData); // Grupos carregados do JSON
  const [selectedGroup, setSelectedGroup] = useState(null); // Novo estado para o grupo selecionado
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false); // Estado para controle do modal de configuração
  const [configModalData, setConfigModalData] = useState(null); // Dados para o modal de configuração
  const [editingGroupId, setEditingGroupId] = useState(null); // Novo estado para identificar o grupo em edição

  const toggleSatelliteView = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  useEffect(() => {
    const counts = {
      acesa: 0,
      apagada: 0,
      semFotocelula: 0,
      semLuminaria: 0,
      alerta: 0,
      offline: 0,
      falha: 0,
      subTensao: 0,
      sobreTensao: 0
    };

    markersData.forEach(marker => {
      if (marker.luminarias.length === 0) {
        counts.semLuminaria++;
      } else {
        marker.luminarias.forEach(luminaria => {
          if (!luminaria.dispositivoEUI) {
            counts.semFotocelula++;
          }
          if (luminaria.isLigado) {
            counts.acesa++;
          } else {
            counts.apagada++;
          }
        });
      }

      const markerAlerts = alertLogs.filter(alert => alert.idPoste === marker.idPoste && alert.status === 'Não Resolvido');
      if (markerAlerts.length > 0) {
        counts.alerta++;
      }
      if (markerAlerts.some(alert => alert.tipoAlerta === 'Subtensão')) {
        counts.subTensao++;
      }
      if (markerAlerts.some(alert => alert.tipoAlerta === 'Sobretensão')) {
        counts.sobreTensao++;
      }
    });

    setMarkerCounts(counts);
  }, []);

  const handleMarkerClick = (marker) => {
    if (isCreatingGroup) {
      if (group.includes(marker.idPoste)) {
        setGroup(group.filter(id => id !== marker.idPoste));
      } else {
        setGroup([...group, marker.idPoste]);
      }
    }
  };

  const getMarkerIcon = (marker) => {
    if (group.includes(marker.idPoste) || (selectedGroup && selectedGroup.postes.includes(marker.idPoste))) {
      return selectedIcon;
    }
    if (marker.luminarias.length === 0) {
      return semLuminariaIcon;
    }

    const alerts = alertLogs.filter(alert => alert.idPoste === marker.idPoste && alert.status === 'Não Resolvido');

    if (alerts.some(alert => alert.tipoAlerta === 'Subtensão')) {
      return subtensaoIcon;
    }

    if (alerts.some(alert => alert.tipoAlerta === 'Sobretensão')) {
      return sobretensaoIcon;
    }

    if (alerts.some(alert => alert.tipoAlerta.includes('Lâmpada'))) {
      return alertaIcon;
    }

    const hasFotocelula = marker.luminarias.some(luminaria => luminaria.dispositivoEUI);
    if (!hasFotocelula) {
      return semFotocelulaIcon;
    }

    const isLigado = marker.luminarias.some(luminaria => luminaria.isLigado);
    return isLigado ? acesaIcon : apagadaIcon;
  };

  const shouldShowMarker = (marker) => {
    if (marker.luminarias.length === 0) {
      return visibleMarkers.semLuminaria;
    }

    const alerts = alertLogs.filter(alert => alert.idPoste === marker.idPoste && alert.status === 'Não Resolvido');
    if (alerts.some(alert => alert.tipoAlerta === 'Subtensão')) {
      return visibleMarkers.subTensao;
    }
    if (alerts.some(alert => alert.tipoAlerta === 'Sobretensão')) {
      return visibleMarkers.sobreTensao;
    }
    if (alerts.some(alert => alert.tipoAlerta.includes('Lâmpada'))) {
      return visibleMarkers.alerta;
    }

    const hasFotocelula = marker.luminarias.some(luminaria => luminaria.dispositivoEUI);
    if (!hasFotocelula) {
      return visibleMarkers.semFotocelula;
    }

    const isLigado = marker.luminarias.some(luminaria => luminaria.isLigado);
    return (isLigado && visibleMarkers.acesa) || (!isLigado && visibleMarkers.apagada);
  };

  const toggleMarkerVisibility = (type) => {
    setVisibleMarkers((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleSearch = () => {
    const cleanedSearchInput = searchInput.replace(/[^0-9]/g, ''); // Remove prefixo e zeros
    const targetMarker = markersData.find(marker => marker.idPoste.toString() === cleanedSearchInput);
    if (targetMarker) {
      if (mapRef.current) {
        const latLng = new window.google.maps.LatLng(targetMarker.lat, targetMarker.lng);
        mapRef.current.panTo(latLng);
        mapRef.current.setZoom(18); // Ajuste o nível de zoom conforme necessário
      }
    } else {
      alert('Poste não encontrado');
    }
  };

  const handleSaveGroup = () => {
    if (!groupName) {
      alert('Por favor, digite um nome para o grupo.');
      return;
    }

    let updatedGroups;

    if (editingGroupId !== null) {
      updatedGroups = groups.map((grp) =>
        grp.id === editingGroupId ? { ...grp, name: groupName, postes: group } : grp
      );
      setEditingGroupId(null);
    } else {
      const newGroup = { id: groups.length + 1, name: groupName, postes: group };
      updatedGroups = [...groups, newGroup];
    }

    setGroups(updatedGroups);
    setGroup([]);
    setGroupName('');
    setIsCreatingGroup(false);
    // Salvar os grupos no JSON
    console.log('Grupos salvos:', updatedGroups);
  };

  const handleDeleteGroup = (groupId) => {
    const updatedGroups = groups.filter(group => group.id !== groupId);
    setGroups(updatedGroups);
    console.log('Grupo deletado:', groupId);
  };

  const handleSelectGroup = (groupId) => {
    const group = groups.find(group => group.id === groupId);
    setSelectedGroup(group);
  };

  const handleEditGroup = (groupId) => {
    const group = groups.find(group => group.id === groupId);
    if (group) {
      setGroup(group.postes);
      setGroupName(group.name);
      setIsCreatingGroup(true);
      setEditingGroupId(groupId);
    }
  };

  const openConfigModal = (group) => {
    setConfigModalData(group);
    setIsConfigModalOpen(true);
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
    setConfigModalData(null);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.mapPage}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        mapTypeId={isSatelliteView ? 'satellite' : 'roadmap'}
        options={{
          styles: isSatelliteView ? [] : lightMapStyles, // Usando apenas o estilo claro
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={map => mapRef.current = map} // Referência ao mapa
      >
        {markersData.map(marker => {
          if (shouldShowMarker(marker)) {
            return (
              <Marker
                key={marker.idPoste}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: getMarkerIcon(marker),
                  scaledSize: new window.google.maps.Size(32, 32),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(16, 16)
                }}
                onClick={() => handleMarkerClick(marker)}
              />
            );
          }
          return null;
        })}
        <Legend markerCounts={markerCounts} toggleMarkerVisibility={toggleMarkerVisibility} visibleMarkers={visibleMarkers} />
        <SatelliteToggleButton isSatelliteView={isSatelliteView} toggleSatelliteView={toggleSatelliteView} />
        <button className={styles.configButton} onClick={() => openConfigModal(null)}>
          <FaCog />
        </button>
        <div className={styles.searchContainer}>
          <FaSearch className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Digite o ID do poste"
            className={styles.searchInput}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className={styles.searchButton} onClick={handleSearch}>Buscar</button>
        </div>
        <div className={styles.groupControls}>
          <button
            className={styles.groupButton}
            onClick={() => setIsCreatingGroup(!isCreatingGroup)}
          >
            {isCreatingGroup ? 'Cancelar' : 'Criar Grupo'}
          </button>
          {isCreatingGroup && (
            <>
              <input
                type="text"
                placeholder="Nome do grupo"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className={styles.groupNameInput}
              />
              <button className={styles.groupButton} onClick={handleSaveGroup}>
                Salvar Grupo
              </button>
            </>
          )}
        </div>
      </GoogleMap>
      <div className={styles.groupList}>
        <h3>Grupos Criados</h3>
        {groups.map(group => (
          <div key={group.id} className={styles.groupItem}>
            <span onClick={() => handleSelectGroup(group.id)}>{group.name}</span>
            <div>
              <button onClick={() => openConfigModal(group)} className={styles.groupEditButton}>
                <FaCog />
              </button>
              <button onClick={() => handleEditGroup(group.id)} className={styles.groupEditButton}>
                <FaEdit />
              </button>
              <button onClick={() => handleDeleteGroup(group.id)} className={styles.groupDeleteButton}>
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
      {isConfigModalOpen && (
        <PosteConfigurationModal
          isOpen={isConfigModalOpen}
          onRequestClose={closeConfigModal}
          poste={configModalData}
        />
      )}
    </div>
  );
};

export default GruposPostes;
