// src/pages/Postes/Postes.jsx

import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaMapMarkerAlt,
  FaChartLine,
  FaSortUp,
  FaSortDown,
  FaCogs,
  FaLightbulb,
  FaCheck, // Importando um ícone para ativação
} from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import PosteModal from './PosteModal';
import GraphModal from './GraphModal';
import PosteConfigurationModal from '../../components/PosteConfigurationModal/PosteConfigurationModal';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import PageLayout from '../../components/PageLayout/PageLayout';
import MapModal from './SubComponents/MapModal';
import PosteComponentsModal from './PosteComponentsModal'; // Importação do novo modal
import styles from './Postes.module.css';

// Importar o hook customizado
import usePosteDataWithDevices from '../../hooks/usePosteDataWithDevices';

const Postes = () => {
  // Obter o token de autenticação
  const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

  // Usar o hook para obter os dados dos postes com dispositivos
  const { postes, isLoading, error, refetch } = usePosteDataWithDevices(token);

  // Estados para os modais e filtros
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isComponentsModalOpen, setIsComponentsModalOpen] = useState(false); // Novo estado para o modal de componentes
  const [selectedPosteForComponents, setSelectedPosteForComponents] = useState(null); // Estado para o poste selecionado
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [includeInactive, setIncludeInactive] = useState(false); // Novo estado para o filtro de postes desativados
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedEUIs, setSelectedEUIs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Estados para o MapModal
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedPosteLocation, setSelectedPosteLocation] = useState({
    latitude: '',
    longitude: '',
  });

  const itemsPerPage = 10;

  // Função auxiliar para acessar propriedades
  const getValue = (obj, key) => obj[key];

  // Função para ordenar os dados
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Funções para adicionar, editar e desativar postes
  const handleAddNew = () => {
    setSelectedPoste(null);
    setIsModalOpen(true);
  };

  const handleEdit = (poste) => {
    setSelectedPoste(poste);
    setIsModalOpen(true);
  };

  // Função para desativar o poste
  const handleDeactivate = async (posteId) => {
    const confirmDeactivate = window.confirm('Tem certeza de que deseja desativar este poste?');
    if (!confirmDeactivate) return;

    try {
      const url = `https://api.ads10.com.br/api/poste/${posteId}`;

      // Primeiro, buscamos os dados atuais do poste
      const responseGet = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (responseGet.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!responseGet.ok) {
        throw new Error(`Erro ao buscar os dados do poste: ${responseGet.statusText}`);
      }

      const posteData = await responseGet.json(); // Obtemos os dados do poste

      // Modificamos apenas o campo 'ativo' para "N"
      const updatedPosteData = { ...posteData, ativo: 'N' };

      // Enviamos os dados atualizados para a API
      const responsePut = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedPosteData), // Enviando todos os dados do poste
      });

      if (responsePut.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!responsePut.ok) {
        throw new Error(`Erro ao desativar o poste: ${responsePut.statusText}`);
      }

      // Atualizar a lista de postes para refletir a desativação
      refetch(); // Recarregar os dados usando o hook
      alert('Poste desativado com sucesso.');
    } catch (err) {
      console.error(err);
      alert('Ocorreu um erro ao desativar o poste.');
    }
  };

  // Função para ativar o poste
  const handleActivate = async (posteId) => {
    const confirmActivate = window.confirm('Tem certeza de que deseja ativar este poste?');
    if (!confirmActivate) return;

    try {
      const url = `https://api.ads10.com.br/api/poste/${posteId}`;

      // Primeiro, buscamos os dados atuais do poste
      const responseGet = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (responseGet.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!responseGet.ok) {
        throw new Error(`Erro ao buscar os dados do poste: ${responseGet.statusText}`);
      }

      const posteData = await responseGet.json(); // Obtemos os dados do poste

      // Modificamos apenas o campo 'ativo' para "S"
      const updatedPosteData = { ...posteData, ativo: 'S' };

      // Enviamos os dados atualizados para a API
      const responsePut = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedPosteData), // Enviando todos os dados do poste
      });

      if (responsePut.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!responsePut.ok) {
        throw new Error(`Erro ao ativar o poste: ${responsePut.statusText}`);
      }

      // Atualizar a lista de postes para refletir a ativação
      refetch(); // Recarregar os dados usando o hook
      alert('Poste ativado com sucesso.');
    } catch (err) {
      console.error(err);
      alert('Ocorreu um erro ao ativar o poste.');
    }
  };

  // Função para salvar um poste (novo ou editado)
  const handleSavePoste = async (payload) => {
    try {
      console.log('Payload recebido no handleSavePoste:', payload);

      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      const method = payload.id ? 'PUT' : 'POST';
      const url = payload.id ? `https://api.ads10.com.br/api/poste/${payload.id}` : `https://api.ads10.com.br/api/poste`;

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      console.log('Resposta da API:', response);

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erro ao salvar o poste:', errorData);
        throw new Error(`Erro ao salvar o poste: ${response.statusText}`);
      }

      await refetch(); // Recarregar os dados após salvar
      alert('Poste salvo com sucesso.');
      setIsModalOpen(false);
    } catch (err) {
      console.error(err);
      alert('Ocorreu um erro ao salvar o poste.');
    }
  };

  // Funções de mudança de estado para filtros e busca
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0); // Resetar para a primeira página ao buscar
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  // Função para mudar o filtro de postes desativados
  const handleIncludeInactiveChange = (e) => {
    setIncludeInactive(e.target.checked);
    setCurrentPage(0); // Resetar para a primeira página
  };

  // Filtrando os postes com base nos filtros e busca
  const filteredPostes = useMemo(() => {
    return postes.filter(poste => {
      const isActive = poste.ativo === 'S'; // Verificar se o poste está ativo
      const matchesSearchTerm = poste.id
        ? poste.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        : false;
      const matchesStatusFilter = statusFilter
        ? (
          statusFilter === 'Alertas'
            ? poste.posteStatus !== 'D'
            : poste.posteStatus === statusFilter
        )
        : true;

      // Exibir desativados apenas se includeInactive for true
      return matchesSearchTerm && matchesStatusFilter && (isActive || includeInactive);
    });
  }, [postes, searchTerm, statusFilter, includeInactive]);

  // Ordenando os postes com base na configuração de ordenação
  const sortedPostes = useMemo(() => {
    const sorted = [...filteredPostes];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        const aValue = getValue(a, sortConfig.key);
        const bValue = getValue(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredPostes, sortConfig]);

  // Paginação
  const pageCount = Math.ceil(sortedPostes.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedPostes.slice(start, end);
  }, [sortedPostes, currentPage, itemsPerPage]);

  // Função para lidar com a mudança de página
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Preparando dados para exportação CSV
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Código do Poste', key: 'codigoPoste' },
    { label: 'Status', key: 'posteStatus' },
    { label: 'Ativo', key: 'ativo' },
    // Adicione outros headers conforme necessário
  ];

  const csvData = useMemo(() => {
    return sortedPostes.map(poste => ({
      id: poste.id,
      codigoPoste: poste.codigoPoste,
      posteStatus: poste.posteStatus,
      ativo: poste.ativo,
      // Adicione outros campos conforme necessário
    }));
  }, [sortedPostes]);

  // Funções para abrir e fechar modais
  const handleOpenGraphModal = (euisAtivos) => {
    setSelectedEUIs(euisAtivos);
    setIsGraphModalOpen(true);
  };

  const handleGraphModalClose = () => {
    setIsGraphModalOpen(false);
    setSelectedEUIs([]);
  };

  const handleOpenConfigModal = (poste) => {
    setSelectedPoste(poste);
    setIsConfigModalOpen(true);
  };

  const handleConfigModalClose = () => {
    setIsConfigModalOpen(false);
    setSelectedPoste(null);
  };

  // Função para abrir o MapModal
  const handleOpenMapModal = (poste) => {
    // Supondo que 'geoLocalizacao' esteja no formato "latitude, longitude"
    if (poste.geoLocalizacao) {
      const [lat, lng] = poste.geoLocalizacao.split(',').map(coord => coord.trim());
      if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
        setSelectedPosteLocation({
          latitude: lat,
          longitude: lng,
        });
        setIsMapModalOpen(true);
      } else {
        alert('Geolocalização inválida para este poste.');
      }
    } else {
      alert('Geolocalização não disponível para este poste.');
    }
  };

  // Função para remover um dispositivo EUI diretamente do servidor
  const handleRemoveEUI = async (posteId, euiDeviceEUI) => {
    const confirmDelete = window.confirm('Tem certeza de que deseja remover este dispositivo EUI?');
    if (!confirmDelete) return;

    try {
      // Buscar o postefotocelulaId correspondente
      const response = await fetch(`https://api.ads10.com.br/api/postefotocelula/poste/${posteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar associações de fotocélula: ${response.statusText}`);
      }

      const associations = await response.json();

      // Encontrar a associação que corresponde ao EUI a ser removido
      const associationToRemove = associations.find(assoc => assoc.fotocelula.deviceEUI === euiDeviceEUI);

      if (!associationToRemove) {
        throw new Error('Associação EUI não encontrada.');
      }

      // Enviar a requisição DELETE para remover a associação
      const deleteResponse = await fetch(`https://api.ads10.com.br/api/postefotocelula/${associationToRemove.postefotocelulaId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (deleteResponse.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!deleteResponse.ok) {
        throw new Error(`Erro ao remover dispositivo EUI: ${deleteResponse.statusText}`);
      }

      // Atualizar a lista de postes após remover o EUI
      refetch();
      alert('Dispositivo EUI removido com sucesso.');
    } catch (err) {
      console.error(err);
      alert('Erro ao remover o dispositivo EUI. Por favor, tente novamente.');
    }
  };

  return (
    <PageLayout title="Lista de Postes">
      {isLoading && <LoadingOverlay />} {/* Tela de carregamento enquanto os dados são carregados */}

      {!isLoading && (
        <div className={styles.container}>
          {/* Exibição de Erro */}
          {error && (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>Erro: {error}</p>
              <button className={styles.retryButton} onClick={refetch}>
                Tentar Novamente
              </button>
            </div>
          )}

          {/* Cabeçalho e Botões de Ação */}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Novo Poste
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"postes-export.csv"}
              className={styles.exportButton}
              bom="true"
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para CSV
            </CSVLink>
          </div>

          {/* Filtros e Busca */}
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID do Poste..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <select
              className={`${styles.searchInput} ${styles.selectInput}`}
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">Filtrar por Status...</option>
              <option value="L">Ligado</option>
              <option value="D">Desligado</option>
              <option value="Alertas">Alertas</option>
            </select>
            {/* Checkbox para incluir postes desativados */}
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={includeInactive}
                onChange={handleIncludeInactiveChange}
              /> 
              Exibir Postes Desativados
            </label>
          </div>

          {/* Tabela de Postes */}
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th onClick={() => requestSort('id')}>
                    ID {sortConfig.key === 'id' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('codigoPoste')}>
                    Código do Poste {sortConfig.key === 'codigoPoste' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('posteStatus')}>
                    Status {sortConfig.key === 'posteStatus' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th>Mapa</th>
                  <th>Gráfico</th>
                  <th>Componentes</th> {/* Nova coluna */}
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map(poste => (
                    <tr key={poste.id} className={poste.ativo === 'N' ? styles.inactivePost : (poste.posteStatus !== 'D' ? styles.posteAlerta : '')}>
                      {/* Exibindo o ID diretamente */}
                      <td>{poste.id}</td>
                      <td>{poste.codigoPoste}</td>
                      <td>{poste.posteStatus === 'L' ? 'Ligado' : poste.posteStatus === 'D' ? 'Desligado' : 'Desconhecido'}</td>
                      <td>
                        <button
                          className={styles.actionButton}
                          onClick={() => handleOpenMapModal(poste)}
                          title="Ver Mapa"
                        >
                          <FaMapMarkerAlt />
                        </button>
                      </td>
                      <td>
                        <button
                          className={styles.actionButton}
                          onClick={() => {
                            const euisAtivos = poste.euis ? poste.euis.filter(eui => eui.ativo === 'S').map(eui => eui.deviceEUI) : [];
                            handleOpenGraphModal(euisAtivos);
                          }}
                          disabled={!poste.euis || poste.euis.filter(eui => eui.ativo === 'S').length === 0}
                          style={{
                            cursor: !poste.euis || poste.euis.filter(eui => eui.ativo === 'S').length === 0 ? 'not-allowed' : 'pointer',
                            opacity: !poste.euis || poste.euis.filter(eui => eui.ativo === 'S').length === 0 ? 0.5 : 1,
                          }}
                          title={poste.euis && poste.euis.filter(eui => eui.ativo === 'S').length > 0 ? 'Ver Gráfico' : 'Nenhum dispositivo EUI ativo associado'}
                        >
                          <FaChartLine />
                        </button>
                      </td>
                      <td>
                        {/* Botão para abrir o novo modal de componentes */}
                        <button
                          className={styles.actionButton}
                          onClick={() => {
                            setSelectedPosteForComponents(poste);
                            setIsComponentsModalOpen(true);
                          }}
                          title="Ver Componentes"
                        >
                          <FaLightbulb />
                        </button>
                      </td>
                      <td>
                        <button className={styles.actionButton} onClick={() => handleEdit(poste)} title="Editar Poste">
                          <FaEdit />
                        </button>
                        {poste.ativo === 'S' ? (
                          <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDeactivate(poste.id)} title="Desativar Poste">
                            <FaTrash />
                          </button>
                        ) : (
                          <button className={`${styles.actionButton} ${styles.activateButton}`} onClick={() => handleActivate(poste.id)} title="Ativar Poste">
                            <FaCheck />
                          </button>
                        )}
                        <button className={styles.actionButton} onClick={() => handleOpenConfigModal(poste)} title="Configurar Poste">
                          <FaCogs />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className={styles.noData}>
                      Nenhum poste encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Componente de Paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              breakLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
              forcePage={currentPage}
            />
          </div>

          {/* Modais */}
          <PosteModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onSubmit={handleSavePoste}
            initialData={selectedPoste}
          />
          <GraphModal
            isOpen={isGraphModalOpen}
            onRequestClose={handleGraphModalClose}
            euis={selectedEUIs}
            handleRemoveEUI={handleRemoveEUI} // Passar a função para remover EUI
          />
          <PosteConfigurationModal
            isOpen={isConfigModalOpen}
            onRequestClose={handleConfigModalClose}
            poste={selectedPoste}
          />
          <MapModal
            isOpen={isMapModalOpen}
            onRequestClose={() => setIsMapModalOpen(false)}
            latitude={selectedPosteLocation.latitude}
            longitude={selectedPosteLocation.longitude}
          />
          {/* Novo modal de componentes */}
          <PosteComponentsModal
            isOpen={isComponentsModalOpen}
            onRequestClose={() => setIsComponentsModalOpen(false)}
            initialData={selectedPosteForComponents}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default Postes;
