// src/components/Sidebar/Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
    FaUser, 
    FaClipboardList, 
    FaBolt, 
    FaFileAlt, 
    FaCog, 
    FaBell, 
    FaTools, 
    FaLightbulb, 
    FaTachometerAlt, 
    FaMap, 
    FaSignOutAlt, 
    FaLink,
    FaBars,
    FaTimes
} from 'react-icons/fa';
import styles from './Sidebar.module.css';
import directLuzLogo from '../../assets/login-directluz-icone.png';
import alertLogs from '../../data/alertLogs.json';

const Sidebar = () => {
    const [isCadastrosOpen, setIsCadastrosOpen] = useState(false);
    const [unresolvedAlerts, setUnresolvedAlerts] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar o Sidebar
    const navigate = useNavigate();
    const location = useLocation(); // Para detectar mudanças de rota

    useEffect(() => {
        const unresolvedCount = alertLogs.filter(alert => alert.status === 'Não Resolvido').length;
        setUnresolvedAlerts(unresolvedCount);
    }, []);

    // Fecha o Sidebar ao mudar de rota (útil para dispositivos móveis)
    useEffect(() => {
        setIsSidebarOpen(false);
    }, [location.pathname]);

    const toggleCadastros = () => {
        setIsCadastrosOpen(!isCadastrosOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Função de logout
    const handleLogout = () => {
        // Remove o token de autenticação do localStorage e sessionStorage
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        // Redireciona o usuário para a página de login
        navigate('/login');
    };

    return (
        <>
            {/* Botão de toggle para mobile */}
            <div 
                className={styles.toggleButton} 
                onClick={toggleSidebar} 
                aria-label={isSidebarOpen ? 'Fechar menu' : 'Abrir menu'}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => { if (e.key === 'Enter') toggleSidebar(); }}
            >
                {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </div>

            {/* Overlay para fechar o Sidebar ao clicar fora */}
            {isSidebarOpen && <div className={styles.overlay} onClick={toggleSidebar}></div>}

            <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}>
                <div className={styles.logo}>
                    <img src={directLuzLogo} alt="DirectLuz Logo" className={styles.logoImage} />
                </div>
                <div className={styles.user}>
                    <FaUser className={styles.icon} />
                    <span className={styles.userName}>João Paulo Mendonça Prado</span>
                </div>
                <nav className={styles.nav}>
                    <ul>
                        <li className={styles.navItem}>
                            <Link to="/dashboard" className={styles.link}>
                                <FaTachometerAlt className={styles.icon} />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/mapa" className={styles.link}>
                                <FaMap className={styles.icon} />
                                <span>Mapa</span>
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <div onClick={toggleCadastros} className={styles.link}>
                                <FaClipboardList className={styles.icon} />
                                <span>Cadastros</span>
                            </div>
                            {isCadastrosOpen && (
                                <ul className={styles.subMenu}>
                                    <li className={styles.subNavItem}>
                                        <Link to="/postes" className={styles.link}>
                                            <FaLightbulb className={styles.icon} />
                                            <span>Postes</span>
                                        </Link>
                                    </li>
                                    <li className={styles.subNavItem}>
                                        <Link to="/dispositivos" className={styles.link}>
                                            <FaTools className={styles.icon} />
                                            <span>Dispositivos</span>
                                        </Link>
                                    </li>
                                    <li className={styles.subNavItem}>
                                        <Link to="/perfis-configuracao" className={styles.link}>
                                            <FaCog className={styles.icon} />
                                            <span>Perfis de Configuração</span>
                                        </Link>
                                    </li>
                                    <li className={styles.subNavItem}>
                                        <Link to="/grupos-postes" className={styles.link}>
                                            <FaMap className={styles.icon} />
                                            <span>Criação de Grupos</span>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/levantamentos" className={styles.link}>
                                <FaBolt className={styles.icon} />
                                <span>Levantamentos</span>
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/relatorios" className={styles.link}>
                                <FaFileAlt className={styles.icon} />
                                <span>Relatórios</span>
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/configuracoes" className={styles.link}>
                                <FaCog className={styles.icon} />
                                <span>Configurações</span>
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/alertas" className={styles.link}>
                                <FaBell className={styles.icon} />
                                <span>Alertas</span>
                                {unresolvedAlerts > 0 && (
                                    <span className={styles.notification}>{unresolvedAlerts}</span>
                                )}
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link to="/ordens-servico" className={styles.link}>
                                <FaTools className={styles.icon} />
                                <span>Ordens de Serviço</span>
                            </Link>
                        </li>
                        {/* Novo item de navegação para "Atrelar Dispositivo" */}
                        <li className={styles.navItem}>
                            <Link to="/atrelar-dispositivo" className={styles.link}>
                                <FaLink className={styles.icon} />
                                <span>Atrelar Dispositivo</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.logoutSection}>
                    <button onClick={handleLogout} className={styles.logoutButton}>
                        <FaSignOutAlt className={styles.icon} />
                        <span>Sair</span>
                    </button>
                </div>
            </div>
        </>

    );

};

export default Sidebar;
