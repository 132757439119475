import React, { useState, useEffect } from 'react';
import { FaExclamationCircle, FaWhatsapp, FaTimes } from 'react-icons/fa'; // Importando ícones de alerta, WhatsApp e fechar
import styles from './MicroAlertWindow.module.css'; // Estilos personalizados

const MicroAlertWindow = ({ alerts, onAlertClick, onCloseAlert }) => {
  const [dismissedAlerts, setDismissedAlerts] = useState(() => {
    // Carregar alertas já vistos do localStorage
    const storedDismissedAlerts = localStorage.getItem('dismissedAlerts');
    return storedDismissedAlerts ? JSON.parse(storedDismissedAlerts) : [];
  });

  useEffect(() => {
    // Salvar alertas vistos no localStorage sempre que o estado mudar
    localStorage.setItem('dismissedAlerts', JSON.stringify(dismissedAlerts));
  }, [dismissedAlerts]);

  // Filtrar alertas que já foram vistos ou resolvidos
  const visibleAlerts = alerts.filter((alert) => !dismissedAlerts.includes(alert.id));

  // Função para gerar o código do poste no formato CEA000000000
  const formatPosteId = (posteId) => {
    return `CEA${posteId.toString().padStart(9, '0')}`; // Gera o ID com 9 dígitos
  };

  // Função para gerar o link de WhatsApp
  const generateWhatsAppLink = (posteId) => {
    const formattedPosteId = formatPosteId(posteId); // Formata o ID do poste
    const message = `@vialuz ceasa ${formattedPosteId}`;
    const phoneNumber = '+5511913454578'; // Número de telefone do WhatsApp
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  // Função para marcar um alerta como visto
  const dismissAlert = (alertId) => {
    setDismissedAlerts((prev) => [...prev, alertId]); // Adiciona o ID ao localStorage
    onCloseAlert(alertId); // Remove o alerta da lista atual
  };

  return (
    <>
      {/* Exibe a janela de alerta apenas se houver alertas visíveis */}
      {visibleAlerts.length > 0 && (
        <div className={styles.alertWindow}>
          {visibleAlerts.map((alert) => (
            <div 
              key={alert.id} 
              className={styles.alertItem}
              onClick={() => onAlertClick(alert)} // Centraliza o poste no mapa ao clicar no card
            >
              {/* Conteúdo principal com ícone de alerta e texto */}
              <div className={styles.alertContent}>
                <FaExclamationCircle className={styles.alertIcon} />
                <div className={styles.alertText}>
                  {alert.alertType} - Poste: {alert.posteId}
                </div>
              </div>
              {/* Ícone do WhatsApp e botão de fechar */}
              <div className={styles.alertActions}>
                <a 
                  href={generateWhatsAppLink(alert.posteId)} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={styles.whatsappIcon}
                  title="Registrar Alerta via WhatsApp"
                  onClick={(e) => {
                    e.stopPropagation(); // Evita que o clique no link do WhatsApp acione o clique no card
                    dismissAlert(alert.id); // Marca o alerta como visto ao clicar no WhatsApp
                  }}
                >
                  <FaWhatsapp />
                </a>
                <button 
                  className={styles.closeButton} 
                  onClick={(e) => {
                    e.stopPropagation(); // Evita que o clique no botão de fechar acione o clique no card
                    dismissAlert(alert.id); // Marca o alerta como visto ao fechar
                  }} 
                  title="Fechar Alerta"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MicroAlertWindow;
