// src/hooks/useRegiao.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de regiões com base no país, estado e cidade selecionados.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @param {string} selectedPais - ID do país selecionado.
 * @param {string} selectedEstado - ID do estado selecionado.
 * @param {string} selectedCidade - ID da cidade selecionada.
 * @returns {object} - Contém a lista de regiões, estado de carregamento, erro e função para refetch.
 */
const useRegiao = (isOpen, selectedPais, selectedEstado, selectedCidade) => {
  const [regioes, setRegioes] = useState([]);
  const [isRegiaoLoading, setIsRegiaoLoading] = useState(false);
  const [regiaoError, setRegiaoError] = useState(null);

  /**
   * Função para buscar as regiões da API com base nos parâmetros selecionados.
   */
  const fetchRegiao = useCallback(async () => {
    // Verifica se o modal está aberto e se todos os parâmetros necessários estão selecionados
    if (!isOpen || !selectedPais || !selectedEstado || !selectedCidade) return;

    setIsRegiaoLoading(true);
    setRegiaoError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      // Construir a URL com query parameters
      const url = new URL('https://api.ads10.com.br/api/regiao');
      url.searchParams.append('pais', selectedPais);
      url.searchParams.append('estado', selectedEstado);
      url.searchParams.append('cidade', selectedCidade);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar regiões: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Regiões recebidas da API:', data); // Log para depuração
      setRegioes(data);
    } catch (error) {
      console.error('Erro ao buscar regiões:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setRegiaoError('Você precisa estar autenticado para carregar a lista de regiões.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de regiões.');
        window.location.href = '/login';
      } else {
        setRegiaoError('Falha ao carregar a lista de regiões. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsRegiaoLoading(false);
    }
  }, [isOpen, selectedPais, selectedEstado, selectedCidade]);

  useEffect(() => {
    fetchRegiao();
  }, [fetchRegiao]);

  return { regioes, isRegiaoLoading, regiaoError, refetch: fetchRegiao };
};

export default useRegiao;
