// src/components/DeviceModal/DeviceModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styles from './DeviceModal.module.css';

const DeviceModal = ({ isOpen, onRequestClose, deviceData, onSave }) => {
  const isEditMode = Boolean(deviceData); // Determina se é modo de edição

  const [formData, setFormData] = useState({
    fotocelulaId: '', // Será usado apenas no modo de edição
    descricao: '',
    deviceEUI: '',
    usuarioId: 0, // Definido como 0; ajuste conforme necessário
    data: '',
    deviceAtualizacao: '',
    latitude: 0,
    longitude: 0,
    corrente: 0,
    potencia: 0,
    tempolampadaligada: 0,
    pa: 0,
    pr: 0,
    pf: 0,
    // Removidos modofotocelula e ativo
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        fotocelulaId: deviceData.fotocelulaId || '',
        descricao: deviceData.descricao || '',
        deviceEUI: deviceData.deviceEUI || '',
        usuarioId: deviceData.usuarioId || 0,
        data: deviceData.data || '',
        deviceAtualizacao: deviceData.deviceAtualizacao || '',
        latitude: deviceData.latitude || 0,
        longitude: deviceData.longitude || 0,
        corrente: deviceData.corrente || 0,
        potencia: deviceData.potencia || 0,
        tempolampadaligada: deviceData.tempolampadaligada || 0,
        pa: deviceData.pa || 0,
        pr: deviceData.pr || 0,
        pf: deviceData.pf || 0,
      });
    } else {
      setFormData({
        descricao: '',
        deviceEUI: '',
        usuarioId: 0, // Definido como 0; ajuste conforme necessário
        data: new Date().toISOString(),
        deviceAtualizacao: new Date().toISOString(),
        latitude: 0,
        longitude: 0,
        corrente: 0,
        potencia: 0,
        tempolampadaligada: 0,
        pa: 0,
        pr: 0,
        pf: 0,
        // Removidos modofotocelula e ativo
      });
    }
  }, [deviceData, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado. Faça login novamente.');
      }

      const method = isEditMode ? 'PUT' : 'POST';
      const url = isEditMode
        ? `https://api.ads10.com.br/api/fotocelula/${formData.fotocelulaId}`
        : 'https://api.ads10.com.br/api/fotocelula';

      // Preparar o payload
      let payload;
      if (isEditMode) {
        payload = {
          ...formData,
          // Inclua campos adicionais se necessário
        };
      } else {
        // Excluir fotocelulaId ao adicionar, já que o backend o gera
        const { fotocelulaId, ...rest } = formData;
        payload = {
          ...rest,
          // Inclua campos adicionais se necessário
        };
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Erro ao salvar dispositivo: ${response.statusText}`);
      }

      const result = await response.json();

      // Chama o callback para atualizar a lista de dispositivos no componente pai
      if (onSave) {
        onSave(result);
      }

      alert(`Dispositivo ${isEditMode ? 'atualizado' : 'adicionado'} com sucesso!`);
      onRequestClose(); // Fecha o modal após salvar
    } catch (err) {
      console.error(err);
      alert(err.message || 'Ocorreu um erro ao salvar o dispositivo.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? "Editar Dispositivo" : "Adicionar Novo Dispositivo"}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false} // Configure isso conforme suas necessidades de acessibilidade
    >
      <button className={styles.closeButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.content}>
        <h2>{isEditMode ? "Editar Dispositivo" : "Adicionar Novo Dispositivo"}</h2>
        <form onSubmit={handleSubmit}>
          {isEditMode && (
            <div className={styles.formGroup}>
              <label htmlFor="fotocelulaId">Fotocélula ID</label>
              <input
                type="text"
                id="fotocelulaId"
                name="fotocelulaId"
                value={formData.fotocelulaId}
                onChange={handleChange}
                disabled // Desabilitado para não permitir edição
              />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="descricao">Descrição</label>
            <input
              type="text"
              id="descricao"
              name="descricao"
              value={formData.descricao}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="deviceEUI">Device EUI</label>
            <input
              type="text"
              id="deviceEUI"
              name="deviceEUI"
              value={formData.deviceEUI}
              onChange={handleChange}
              required
            />
          </div>
          {/* Removidos os campos "Modo Fotocélula" e "Ativo" */}
          <div className={styles.formActions}>
            <button type="submit" className={styles.submitButton}>
              {isEditMode ? "Salvar Alterações" : "Adicionar Dispositivo"}
            </button>
            <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DeviceModal;
