// src/hooks/useCities.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de cidades com base no estado selecionado.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @param {string|number} stateId - ID do estado para filtrar as cidades.
 * @returns {object} - Contém a lista de cidades, estado de carregamento, erro e função para refetch.
 */
const useCities = (isOpen, stateId) => {
  const [cities, setCities] = useState([]);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [citiesError, setCitiesError] = useState(null);

  /**
   * Função para buscar as cidades da API com base no estado selecionado.
   */
  const fetchCities = useCallback(async () => {
    if (!isOpen || !stateId) return; // Não buscar se o modal não estiver aberto ou se o estado não estiver selecionado

    setIsCitiesLoading(true);
    setCitiesError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      // Supondo que o endpoint aceita um parâmetro de query para filtrar por estado
      const response = await fetch(`https://api.ads10.com.br/api/cidade?estado=${stateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar cidades: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Cidades recebidas da API:', data); // Log para depuração
      setCities(data);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setCitiesError('Você precisa estar autenticado para carregar a lista de cidades.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de cidades.');
        window.location.href = '/login';
      } else {
        setCitiesError('Falha ao carregar a lista de cidades. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsCitiesLoading(false);
    }
  }, [isOpen, stateId]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  return { cities, isCitiesLoading, citiesError, refetch: fetchCities };
};

export default useCities;
