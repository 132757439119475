import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaSave } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './ConfigurationProfileModal.module.css';

const ConfigurationProfileModal = ({ isOpen, onRequestClose, onSave, initialData }) => {
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dimValue, setDimValue] = useState(50);
  const [energySavings, setEnergySavings] = useState(0);
  const [costSavings, setCostSavings] = useState(0);

  const electricityRate = 0.5; // Assumindo uma tarifa de energia de R$0,5 por kWh
  const baselineConsumption = 100; // Assumindo um consumo base de 100 kWh

  useEffect(() => {
    if (initialData) {
      setName(initialData.name || '');
      setStartTime(initialData.startTime || '');
      setEndTime(initialData.endTime || '');
      setDimValue(initialData.dimValue || 50);
    } else {
      setName('');
      setStartTime('');
      setEndTime('');
      setDimValue(50);
    }
  }, [initialData]);

  useEffect(() => {
    const savings = baselineConsumption * ((100 - dimValue) / 100);
    setEnergySavings(savings);
    setCostSavings(savings * electricityRate);
  }, [dimValue]);

  const handleSave = () => {
    onSave({ id: initialData ? initialData.id : null, name, startTime, endTime, dimValue });
    onRequestClose();
  };

  const data = [
    { name: 'Consumo Base', value: baselineConsumption },
    { name: 'Economia de Energia', value: energySavings }
  ];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{initialData ? 'Editar Perfil' : 'Novo Perfil'}</h2>
        <div className={styles.controlGroup}>
          <label htmlFor="name">Nome do Perfil:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.controlGroup}>
          <label htmlFor="startTime">Hora de Acender:</label>
          <input
            type="time"
            id="startTime"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.controlGroup}>
          <label htmlFor="endTime">Hora de Apagar:</label>
          <input
            type="time"
            id="endTime"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.controlGroup}>
          <label>Dimerização:</label>
          <input
            type="range"
            min="0"
            max="100"
            value={dimValue}
            onChange={(e) => setDimValue(e.target.value)}
            className={styles.slider}
          />
          <span>{dimValue}%</span>
        </div>
        <div className={styles.energySavings}>
          <h3>Economia Estimada</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <p>Energia Economizada: {energySavings.toFixed(2)} kWh</p>
          <p>Economia em Reais: R${costSavings.toFixed(2)}</p>
        </div>
        <button onClick={handleSave} className={styles.saveButton}>
          <FaSave /> Salvar
        </button>
      </div>
    </Modal>
  );
};

export default ConfigurationProfileModal;
