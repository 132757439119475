import React, { useState, useEffect } from 'react';
import { FaEdit, FaSortUp, FaSortDown } from 'react-icons/fa';
import serviceOrdersData from '../../data/serviceOrders.json';
import styles from './OrdensServicos.module.css';
import EditServiceOrderModal from './EditServiceOrderModal';

const ServiceOrderListPage = () => {
  const [serviceOrders, setServiceOrders] = useState([]);
  const [filteredServiceOrders, setFilteredServiceOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    setServiceOrders(serviceOrdersData);
    setFilteredServiceOrders(serviceOrdersData);
  }, []);

  const handleEditClick = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  const handleSaveOrder = (updatedOrder) => {
    const updatedOrders = serviceOrders.map(order => order.idOrdemServico === updatedOrder.idOrdemServico ? updatedOrder : order);
    setServiceOrders(updatedOrders);
    setFilteredServiceOrders(updatedOrders);
    setIsModalOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleDescriptionFilterChange = (e) => {
    setDescriptionFilter(e.target.value);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filteredData = serviceOrders.filter(order => 
      order.idOrdemServico.toString().toLowerCase().includes(lowercasedSearchTerm) ||
      order.cidade.toLowerCase().includes(lowercasedSearchTerm) ||
      order.regiao.toLowerCase().includes(lowercasedSearchTerm) ||
      order.bairro.toLowerCase().includes(lowercasedSearchTerm) ||
      order.prestador.toLowerCase().includes(lowercasedSearchTerm) ||
      order.descricaoAlerta.toLowerCase().includes(lowercasedSearchTerm) ||
      order.status.toLowerCase().includes(lowercasedSearchTerm)
    ).filter(order =>
      (statusFilter ? order.status === statusFilter : true) &&
      (descriptionFilter ? order.descricaoAlerta === descriptionFilter : true)
    );
    setFilteredServiceOrders(filteredData);
  }, [searchTerm, statusFilter, descriptionFilter, serviceOrders]);

  const sortedServiceOrders = () => {
    const sortedOrders = [...filteredServiceOrders];
    if (sortConfig.key) {
      sortedOrders.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedOrders;
  };

  return (
    <div className={styles.serviceOrderListPage}>
      <h1 className={styles.header}>Lista de Ordens de Serviço</h1>
      <div className={styles.controls}>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Pesquisar..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select
          className={`${styles.searchInput} ${styles.selectInput}`}
          value={statusFilter}
          onChange={handleStatusFilterChange}
        >
          <option value="">Filtrar por Status...</option>
          <option value="Pendente">Pendente</option>
          <option value="Resolvida">Resolvida</option>
          <option value="Cancelada">Cancelada</option>
        </select>
        <select
          className={`${styles.searchInput} ${styles.selectInput}`}
          value={descriptionFilter}
          onChange={handleDescriptionFilterChange}
        >
          <option value="">Filtrar por Descrição do Alerta...</option>
          <option value="Sobretensão">Sobretensão</option>
          <option value="Subtensão">Subtensão</option>
          <option value="Lâmpada apagada durante a noite">Lâmpada apagada durante a noite</option>
          <option value="Lâmpada acesa durante o dia">Lâmpada acesa durante o dia</option>
          <option value="Risco de Choque elétrico">Risco de Choque elétrico</option>
        </select>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th onClick={() => requestSort('idOrdemServico')}>
              ID {sortConfig.key === 'idOrdemServico' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('idAlerta')}>
              ID do Alerta {sortConfig.key === 'idAlerta' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('idPoste')}>
              Poste {sortConfig.key === 'idPoste' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('cidade')}>
              Cidade {sortConfig.key === 'cidade' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('regiao')}>
              Região {sortConfig.key === 'regiao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('bairro')}>
              Bairro {sortConfig.key === 'bairro' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('prestador')}>
              Prestador {sortConfig.key === 'prestador' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('descricaoAlerta')}>
              Descrição do Alerta {sortConfig.key === 'descricaoAlerta' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('status')}>
              Status {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedServiceOrders().map(order => (
            <tr key={order.idOrdemServico}>
              <td>{order.idOrdemServico}</td>
              <td>{order.idAlerta}</td>
              <td>{order.idPoste}</td>
              <td>{order.cidade}</td>
              <td>{order.regiao}</td>
              <td>{order.bairro}</td>
              <td>{order.prestador}</td>
              <td>{order.descricaoAlerta}</td>
              <td>{order.status}</td>
              <td>
                <button className={styles.actionButton} onClick={() => handleEditClick(order)}>
                  <FaEdit />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedOrder && (
        <EditServiceOrderModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          order={selectedOrder}
          onSave={handleSaveOrder}
        />
      )}
    </div>
  );
};

export default ServiceOrderListPage;
