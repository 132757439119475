// src/components/Dashboard/Dashboard.jsx

import React, { useEffect, useState } from 'react';
import {
  format,
  subMonths,
  endOfMonth,
  parseISO,
  isSameMonth,
  getDate,
} from 'date-fns';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ChartCard from '../../components/ChartCard/ChartCard';
import styles from './Dashboard.module.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384'];
const STATUS_COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];
const POSTE_COLORS = ['#FF8042', '#00C49F'];
const BAR_COLOR = '#8884d8';
const LINE_COLOR = '#82ca9d';

const statusMap = {
  '1': 'Aguardando',
  '2': 'Atendendo',
  '3': 'Cancelada',
  '4': 'Finalizada',
};

const Dashboard = () => {
  const [alertData, setAlertData] = useState({
    internos: [],
    externos: [],
    status: [],
    postes: [],
    last4Months: [],
    currentMonth: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(60); // Tempo restante para a próxima atualização

  const fetchDashboardData = async () => {
    try {
      const token =
        localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const [alertaResponse, posteResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/alerta', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch('https://api.ads10.com.br/api/postefotocelula', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      if (!alertaResponse.ok || !posteResponse.ok) {
        if (
          alertaResponse.status === 401 ||
          posteResponse.status === 401
        ) {
          alert('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }
        throw new Error('Erro ao buscar os dados da API');
      }

      const [alertaData, posteData] = await Promise.all([
        alertaResponse.json(),
        posteResponse.json(),
      ]);

      const processedAlertaData = processAlertData(alertaData);
      const processedPosteData = processPosteData(posteData);
      const processedLast4Months = processAlertDataLast4Months(alertaData);
      const processedCurrentMonth = processAlertDataCurrentMonth(alertaData);

      setAlertData({
        ...processedAlertaData,
        postes: processedPosteData,
        last4Months: processedLast4Months,
        currentMonth: processedCurrentMonth,
      });
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido');
    } finally {
      setIsLoading(false);
    }
  };

  const processAlertData = (data) => {
    const internos = {
      Outros: 0,
      'Ponto Apagado': 0,
      'Material Danificado': 0,
      Implantação: 0,
    };
    const externos = {
      Outros: 0,
      'Falta de Energia': 0,
      'Queda de Tensão': 0,
      'Risco de Choque Elétrico': 0,
      'Limpeza das Instalações': 0,
      'Novas Instalações': 0,
      'Ampliação de Rede': 0,
      'Material Danificado': 0,
    };
    const status = {
      Aguardando: 0,
      Atendendo: 0,
      Cancelada: 0,
      Finalizada: 0,
    };

    data.forEach((alert) => {
      const [tipoAlerta, motivoRaw] = alert.alertaMotivo
        .split('/')
        .map((item) => item.trim());

      if (tipoAlerta.includes('Interno aos Galpões')) {
        if (motivoRaw.includes('Ponto Apagado')) {
          internos['Ponto Apagado'] += 1;
        } else if (motivoRaw.includes('Material Danificado')) {
          internos['Material Danificado'] += 1;
        } else if (motivoRaw.includes('Implantação')) {
          internos['Implantação'] += 1;
        } else {
          internos.Outros += 1;
        }
      } else if (tipoAlerta.includes('Externo aos Galpões')) {
        if (motivoRaw.includes('Falta de Energia')) {
          externos['Falta de Energia'] += 1;
        } else if (motivoRaw.includes('Queda de Tensão')) {
          externos['Queda de Tensão'] += 1;
        } else if (motivoRaw.includes('Risco de Choque Elétrico')) {
          externos['Risco de Choque Elétrico'] += 1;
        } else if (motivoRaw.includes('Limpeza das Instalações')) {
          externos['Limpeza das Instalações'] += 1;
        } else if (motivoRaw.includes('Novas Instalações')) {
          externos['Novas Instalações'] += 1;
        } else if (motivoRaw.includes('Ampliação de Rede')) {
          externos['Ampliação de Rede'] += 1;
        } else if (motivoRaw.includes('Material Danificado')) {
          externos['Material Danificado'] += 1;
        } else {
          externos.Outros += 1;
        }
      }

      const situacao = statusMap[alert.alertaSituacao] || 'Desconhecido';
      if (situacao !== 'Desconhecido') {
        status[situacao] += 1;
      }
    });

    return {
      internos: transformToChartData(internos),
      externos: transformToChartData(externos),
      status: [
        { name: 'Aguardando', value: status['Aguardando'] },
        { name: 'Atendendo', value: status['Atendendo'] },
        { name: 'Cancelada', value: status['Cancelada'] },
        { name: 'Finalizada', value: status['Finalizada'] },
      ],
    };
  };

  const processPosteData = (posteData) => {
    const ativos = posteData.filter((poste) => poste.ativo === 'S');
    let ligado = 0;
    let desligado = 0;

    ativos.forEach((poste) => {
      if (poste.ligado === 'L') {
        ligado += 1;
      } else if (poste.ligado === 'D') {
        desligado += 1;
      }
    });

    return [
      { name: 'Ligado', value: ligado },
      { name: 'Desligado', value: desligado },
    ];
  };

  const processAlertDataLast4Months = (data) => {
    const today = new Date();
    const last4Months = [];

    for (let i = 3; i >= 0; i--) {
      const date = subMonths(today, i);
      const month = format(date, 'MMMM yyyy');
      last4Months.push(month);
    }

    const alertByMonth = {};
    last4Months.forEach((month) => {
      alertByMonth[month] = 0;
    });

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      const alertMonth = format(alertDate, 'MMMM yyyy');

      if (alertByMonth.hasOwnProperty(alertMonth)) {
        alertByMonth[alertMonth] += 1;
      }
    });

    return last4Months.map((month) => ({
      month,
      count: alertByMonth[month],
    }));
  };

  const processAlertDataCurrentMonth = (data) => {
    const today = new Date();
    const endOfCurrentMonth = endOfMonth(today);
    const daysInMonth = getDate(endOfCurrentMonth);
    const alertByDay = {};

    for (let day = 1; day <= daysInMonth; day++) {
      alertByDay[day] = 0;
    }

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      if (isSameMonth(alertDate, today)) {
        const day = getDate(alertDate);
        alertByDay[day] += 1;
      }
    });

    const chartData = [];
    for (let day = 1; day <= daysInMonth; day++) {
      chartData.push({
        day: day.toString(),
        count: alertByDay[day],
      });
    }

    return chartData;
  };

  const transformToChartData = (obj) => {
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));
  };

  useEffect(() => {
    fetchDashboardData();

    const dataInterval = setInterval(() => {
      fetchDashboardData();
      setTimeLeft(60);
    }, 60000); // Atualiza os dados a cada 60 segundos

    const countdownTimer = setInterval(() => {
      setTimeLeft((prevTimeLeft) =>
        prevTimeLeft > 0 ? prevTimeLeft - 1 : 0
      );
    }, 1000); // Decrementa o tempo a cada segundo

    return () => {
      clearInterval(dataInterval);
      clearInterval(countdownTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return (
      <PageLayout title="Dashboard">
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>Erro: {error}</p>
          <button
            className={styles.retryButton}
            onClick={fetchDashboardData}
          >
            Tentar Novamente
          </button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Dashboard">
      <div className={styles.dashboardContent}>
        <ChartCard
          title="Alertas Internos"
          type="pie"
          data={alertData.internos}
          dataKey="value"
          colors={COLORS}
          label
        />
        <ChartCard
          title="Alertas Externos"
          type="pie"
          data={alertData.externos}
          dataKey="value"
          colors={COLORS}
          label
        />
        <ChartCard
          title="Alertas por Status"
          type="pie"
          data={alertData.status}
          dataKey="value"
          colors={STATUS_COLORS}
          label={({ name, percent }) =>
            `${name}: ${(percent * 100).toFixed(0)}%`
          }
        />
        <ChartCard
          title="Status de Postes"
          type="pie"
          data={alertData.postes}
          dataKey="value"
          colors={POSTE_COLORS}
          label
        />
        <ChartCard
          title="Total de Alertas nos Últimos 4 Meses"
          type="bar"
          data={alertData.last4Months}
          dataKey="count"
          colors={[BAR_COLOR]}
          additionalProps={{
            margin: { top: 20, right: 30, left: 20, bottom: 5 },
          }}
        />
        <ChartCard
          title="Alertas Criados no Mês Atual"
          type="line"
          data={alertData.currentMonth}
          dataKey="count"
          colors={[LINE_COLOR]}
          additionalProps={{
            margin: { top: 20, right: 30, left: 20, bottom: 5 },
          }}
        />
      </div>

      {/* Barra de Carregamento */}
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${((60 - timeLeft) / 60) * 100}%` }}
        ></div>
        <div className={styles.loadingText}>
          Atualização em {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
