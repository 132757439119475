// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

  if (!token) {
    // Se o token não existir, redireciona para a página de login
    return <Navigate to="/login" replace />;
  }

  // Se o token existir, renderiza o componente filho
  return children;
};

export default ProtectedRoute;
