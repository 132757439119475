// src/pages/Mapa/Mapa.jsx

import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import styles from './Mapa.module.css';
import Legend from './Legend';
import SatelliteToggleButton from './SatelliteToggleButton';
import CompanyLogos from './Logos';
import MarkerModal from '../../components/MarkerModal/MarkerModal';
import { FaSearch } from 'react-icons/fa';
import { lightMapStyles } from '../../config/mapStyles';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import MicroAlertWindow from '../../components/MicroAlertWindow/MicroAlertWindow';
import { MAP_CENTER } from '../../config'; // Importando o center

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const Mapa = () => {
  const [alerts, setAlerts] = useState([]);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [markersData, setMarkersData] = useState([]);
  const [alertsByPoste, setAlertsByPoste] = useState({});
  const [markerCounts, setMarkerCounts] = useState({
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    fotocelulasAtivas: 0,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState({
    acesa: true,
    apagada: true,
    semFotocelula: false,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    fotocelulasAtivas: true,
  });
  const [searchInput, setSearchInput] = useState('');
  const [timeLeft, setTimeLeft] = useState(180);
  const [highlightedMarker, setHighlightedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const mapRef = useRef(null);

  // Mapeamento de zIndex para controlar a ordem de empilhamento dos marcadores
  const markerZIndex = {
    alerta: 6,
    sobreTensao: 5,
    subTensao: 4,
    fotocelulasAtivas: 3,
    acesa: 3,
    apagada: 2,
    semFotocelula: 1,
  };

  const toggleSatelliteView = () => {
    setIsSatelliteView((prev) => !prev);
  };

  const fetchMarkersAndAlerts = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        console.error('Token de autenticação não encontrado');
        return;
      }

      const currentHour = new Date().getHours();

      // Fetch dos postes
      const markersResponse = await fetch('https://ceasa.ads10.com.br/resultado.php?c=3315');
      const markersText = await markersResponse.text();
      const parser = new DOMParser();
      const xml = parser.parseFromString(markersText, 'application/xml');
      const markers = Array.from(xml.querySelectorAll('marker')).map((marker) => ({
        idPoste: marker.getAttribute('id'),
        name: marker.getAttribute('name'),
        lat: parseFloat(marker.getAttribute('lat')),
        lng: parseFloat(marker.getAttribute('lng')),
        status: marker.getAttribute('status'),
        tensaoRede: marker.getAttribute('tensaoRede'),
        tipo: marker.getAttribute('tipo'),
        fotocelulas: marker.getAttribute('fotocelulas'),
      }));
      setMarkersData(markers);

      // Buscar os alertas
      const alertsResponse = await fetch('https://api.ads10.com.br/api/alerta', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!alertsResponse.ok) {
        throw new Error(`Erro ao buscar alertas: ${alertsResponse.status}`);
      }
      const alertsData = await alertsResponse.json();

      const alertsByPosteTemp = {};
      alertsData.forEach((alert) => {
        const posteId = alert.alertaPosteId;
        if (!alertsByPosteTemp[posteId]) {
          alertsByPosteTemp[posteId] = [];
        }
        alertsByPosteTemp[posteId].push(alert);
      });
      setAlertsByPoste(alertsByPosteTemp);

      const counts = {
        acesa: 0,
        apagada: 0,
        semFotocelula: 0,
        alerta: 0,
        subTensao: 0,
        sobreTensao: 0,
        fotocelulasAtivas: 0,
      };

      const openAlertSituations = ['1', '2'];
      const microAlertsTemp = [];

      const responsePosteFotocelula = await fetch(`https://api.ads10.com.br/api/postefotocelula`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!responsePosteFotocelula.ok) {
        throw new Error(`Erro ao buscar postefotocelula: ${responsePosteFotocelula.status}`);
      }

      const postefotocelulaData = await responsePosteFotocelula.json();

      markers.forEach((marker) => {
        const tensao = parseFloat(marker.tensaoRede);
        const statusNormalized = marker.status.trim().toUpperCase();

        const markerAlerts = alertsByPosteTemp[marker.idPoste] || [];
        const hasOpenAlert = markerAlerts.some((alert) =>
          openAlertSituations.includes(alert.alertaSituacao)
        );
        marker.hasOpenAlert = hasOpenAlert;

        if (hasOpenAlert) {
          counts.alerta++;
        }

        if (marker.fotocelulas === '0') {
          counts.semFotocelula++;
        }

        const postefotocelulaForPoste = postefotocelulaData.filter(
          (pf) => pf.posteId === marker.idPoste && pf.ativo === 'S'
        );
        const fotocelulasAtivas = postefotocelulaForPoste.length;

        counts.fotocelulasAtivas += fotocelulasAtivas;
        marker.fotocelulasAtivas = fotocelulasAtivas;

        if (tensao && tensao < 220 * 0.9) {
          counts.subTensao++;
          microAlertsTemp.push({
            id: `${marker.idPoste}-subtensao`,
            alertType: 'Subtensão detectada',
            posteId: marker.idPoste,
          });
        }

        if (tensao && tensao > 220 * 1.1) {
          counts.sobreTensao++;
          microAlertsTemp.push({
            id: `${marker.idPoste}-sobretensao`,
            alertType: 'Sobretensão detectada',
            posteId: marker.idPoste,
          });
        }

        if (marker.fotocelulas !== '0' && statusNormalized === '(LIGADO)' && currentHour >= 6 && currentHour < 18) {
          microAlertsTemp.push({
            id: `${marker.idPoste}-ligado-dia`,
            alertType: 'Poste ligado durante o dia (com fotocélula)',
            posteId: marker.idPoste,
          });
        }

        if (marker.fotocelulas !== '0' && statusNormalized !== '(LIGADO)' && (currentHour >= 18 || currentHour < 6)) {
          microAlertsTemp.push({
            id: `${marker.idPoste}-desligado-noite`,
            alertType: 'Poste desligado durante a noite (com fotocélula)',
            posteId: marker.idPoste,
          });
        }

        if (statusNormalized === '(LIGADO)') {
          counts.acesa++;
        }

        if (statusNormalized !== '(LIGADO)' && marker.fotocelulas !== '0') {
          counts.apagada++;
        }
      });

      setMarkerCounts(counts);
      setAlerts(microAlertsTemp); // Atualizar os micro alertas
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    }
  };

  useEffect(() => {
    fetchMarkersAndAlerts();

    const intervalId = setInterval(() => {
      fetchMarkersAndAlerts();
      setTimeLeft(180);
    }, 180000); // 3 minutos

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000); // 1 segundo

    return () => {
      clearInterval(intervalId);
      clearInterval(timer);
    };
  }, []);

  const handleMarkerClick = async (marker) => {
    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

    if (!token) {
      console.error('Token de autenticação não encontrado');
      return;
    }

    // Ativar a tela de carregamento
    setIsLoading(true);

    try {
      // Fetch dos detalhes do poste
      const responsePoste = await fetch(`https://api.ads10.com.br/api/poste/${marker.idPoste}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!responsePoste.ok) {
        throw new Error(`Erro ao buscar poste: ${responsePoste.status}`);
      }

      const posteData = await responsePoste.json();

      // Fetch das associações entre postes e fotocelulas
      const responsePosteFotocelula = await fetch(
        `https://api.ads10.com.br/api/postefotocelula?posteId=${marker.idPoste}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!responsePosteFotocelula.ok) {
        throw new Error(`Erro ao buscar postefotocelula: ${responsePosteFotocelula.status}`);
      }

      const postefotocelulaData = await responsePosteFotocelula.json();

      const fotocelulaIdsAtivas = postefotocelulaData
        .filter((pf) => pf && pf.fotocelulaId && pf.posteId === marker.idPoste && pf.ativo === 'S')
        .map((pf) => pf.fotocelulaId.toString());

      const responseFotocelulas = await fetch('https://api.ads10.com.br/api/fotocelula', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!responseFotocelulas.ok) {
        throw new Error(`Erro ao buscar fotocelulas: ${responseFotocelulas.status}`);
      }

      const fotocelulasData = await responseFotocelulas.json();

      const fotocelulasAtivas = fotocelulasData.filter(
        (f) => fotocelulaIdsAtivas.includes(f.fotocelulaId.toString()) && f.ativo === 'S'
      );

      const markerAlerts = alertsByPoste[marker.idPoste] || [];

      setSelectedMarker({ ...marker, posteData, fotocelulas: fotocelulasAtivas, alerts: markerAlerts });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Erro ao carregar detalhes do marcador:', error);
    } finally {
      // Desativar a tela de carregamento
      setIsLoading(false);
    }
  };

  // Função para centralizar o mapa no marcador e tocar a animação
  const focusOnMarker = (markerId) => {
    const targetMarker = markersData.find((marker) => marker.idPoste === markerId);
    if (targetMarker && mapRef.current) {
      const latLng = new window.google.maps.LatLng(targetMarker.lat, targetMarker.lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18); // Zoom no marcador
      highlightMarker(markerId); // Tocar a animação
    }
  };

  const handleAlertClick = (alert) => {
    focusOnMarker(alert.posteId); // Centralizar o mapa no poste vinculado ao alerta
  };

  // Função para remover o alerta da lista
  const handleCloseAlert = (alertId) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMarker(null);
  };

  // Função para determinar o tipo de marcador
  const getMarkerType = (marker) => {
    const tensao = parseFloat(marker.tensaoRede);

    // Prioridade 1: Verificar se há alerta em aberto
    if (marker.hasOpenAlert) {
      return 'alerta'; // Prioridade máxima: ícone de alerta
    }

    // Prioridade 2: Verificar se é um poste sem fotocélula
    if (marker.fotocelulas === '0') {
      return 'semFotocelula';
    }

    // Prioridade 3: Verificar por subtensão e sobretensão
    if (tensao && tensao < 220 * 0.9) {
      return 'subTensao';
    } else if (tensao && tensao > 220 * 1.1) {
      return 'sobreTensao';
    }

    // Prioridade 4: Verificar se há fotocélulas ativas e se está aceso ou apagado
    if (Array.isArray(marker.fotocelulasAtivas) && marker.fotocelulasAtivas.length > 0) {
      const isFotocelulaLigada = marker.fotocelulasAtivas.some((fotocelula) => fotocelula.ligado === 'L');
      if (isFotocelulaLigada) {
        return 'acesa'; // Poste ligado
      } else {
        return 'apagada'; // Poste desligado
      }
    }

    // Prioridade 5: Verificar o status do poste diretamente
    const statusNormalized = marker.status.trim().toUpperCase();
    if (statusNormalized === '(LIGADO)') {
      return 'acesa'; // Poste ligado
    } else {
      return 'apagada'; // Poste desligado
    }
  };

  // Função para obter o ícone do marcador
  const getMarkerIcon = (marker) => {
    const type = getMarkerType(marker);

    switch (type) {
      case 'semFotocelula':
        return semFotocelulaIcon;
      case 'subTensao':
        return subtensaoIcon;
      case 'sobreTensao':
        return sobretensaoIcon;
      case 'alerta':
        return alertaIcon;
      case 'acesa':
        return acesaIcon;
      case 'apagada':
        return apagadaIcon;
      default:
        return apagadaIcon;
    }
  };

  // Função para ordenar os marcadores e evitar alternância na sobreposição
  const getOrderedMarkers = () => {
    return [...markersData].sort((a, b) => {
      const zIndexA = markerZIndex[getMarkerType(a)] || 0;
      const zIndexB = markerZIndex[getMarkerType(b)] || 0;
      return zIndexA - zIndexB;
    });
  };

  const highlightMarker = (markerId) => {
    setHighlightedMarker(markerId);
    setTimeout(() => setHighlightedMarker(null), 2000); // Animação de bounce por 2 segundos
  };

  const shouldShowMarker = (marker) => {
    const type = getMarkerType(marker);
    return visibleMarkers[type];
  };

  const toggleMarkerVisibility = (type) => {
    setVisibleMarkers((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleSearch = () => {
    const cleanedSearchInput = searchInput.replace(/[^0-9]/g, '');
    const targetMarker = markersData.find((marker) => marker.idPoste === cleanedSearchInput);
    if (targetMarker) {
      focusOnMarker(targetMarker.idPoste); // Usar a função para centralizar e animar
    } else {
      alert('Poste não encontrado');
    }
  };

  return (
    <div className={styles.mapPage}>
      {isLoading && <LoadingOverlay />} {/* Tela de carregamento enquanto o modal está carregando */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={MAP_CENTER} // Utilizando o center do config.js
        zoom={17}
        mapTypeId={isSatelliteView ? 'satellite' : 'roadmap'}
        options={{
          styles: isSatelliteView ? [] : lightMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={(map) => (mapRef.current = map)}
      >
        {getOrderedMarkers().map(
          (marker, index) =>
            shouldShowMarker(marker) && (
              <Marker
                key={`${marker.idPoste}-${index}`}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: getMarkerIcon(marker),
                  scaledSize: new window.google.maps.Size(22, 22),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(16, 16),
                }}
                zIndex={markerZIndex[getMarkerType(marker)]}
                animation={
                  highlightedMarker === marker.idPoste
                    ? window.google.maps.Animation.BOUNCE
                    : null
                }
                onClick={() => handleMarkerClick(marker)}
                title={`ID do Poste: ${marker.idPoste}`} // Exibe o título ao passar o mouse
              />
            )
        )}

        <Legend
          markerCounts={markerCounts}
          toggleMarkerVisibility={toggleMarkerVisibility}
          visibleMarkers={visibleMarkers}
        />
        <SatelliteToggleButton
          isSatelliteView={isSatelliteView}
          toggleSatelliteView={toggleSatelliteView}
        />
        <CompanyLogos />
        <div className={styles.searchContainer}>
          <FaSearch className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Digite o ID do poste"
            className={styles.searchInput}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className={styles.searchButton} onClick={handleSearch}>
            Buscar
          </button>
        </div>
        {selectedMarker && (
          <MarkerModal
            isOpen={isModalOpen}
            onClose={closeModal}
            markerData={selectedMarker}
          />
        )}

        {/* Janela de Micro Alertas */}
        <MicroAlertWindow
          alerts={alerts}
          onAlertClick={handleAlertClick}
          onCloseAlert={handleCloseAlert}
        />
        <div className={styles.loadingBarContainer}>
          <div
            className={styles.loadingBar}
            style={{ width: `${(180 - timeLeft) / 1.8}%` }}
          ></div>
          <div className={styles.loadingText}>
            Atualização em {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toString().padStart(2, '0')}
          </div>
        </div>
      </GoogleMap>
    </div>
  );
};

export default Mapa;
