// src/hooks/useStates.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de estados.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de estados, estado de carregamento, erro e função para refetch.
 */
const useStates = (isOpen) => {
  const [states, setStates] = useState([]);
  const [isStatesLoading, setIsStatesLoading] = useState(false);
  const [statesError, setStatesError] = useState(null);

  /**
   * Função para buscar os estados da API.
   */
  const fetchStates = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsStatesLoading(true);
    setStatesError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/estado', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar estados: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Estados recebidos da API:', data); // Log para depuração
      setStates(data);
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setStatesError('Você precisa estar autenticado para carregar a lista de estados.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de estados.');
        window.location.href = '/login';
      } else {
        setStatesError('Falha ao carregar a lista de estados. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsStatesLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  return { states, isStatesLoading, statesError, refetch: fetchStates };
};

export default useStates;
