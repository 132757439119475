// src/pages/Postes/SubComponents/IdentificacaoLocalizacao.jsx

import React from 'react';
import {
  FaIdCard,
  FaCode,
  FaInfoCircle,
  FaTag,
  FaMapMarkerAlt,
  FaGlobe,
  FaMapSigns,
  FaCity,
  FaMap,
} from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const IdentificacaoLocalizacao = ({
  formData,
  handleChange,
  handleGetUserLocation,
  countries,
  isCountriesLoading,
  countriesError,
  states,
  isStatesLoading,
  statesError,
  cities,
  isCitiesLoading,
  citiesError,
  regioes,
  isRegiaoLoading,
  regiaoError,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Identificação</legend>
      <div className={styles.formGroup}>
        {/* ID - Não Editável */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaIdCard /> ID
          </label>
          <input
            className={styles.input}
            type="text"
            name="idPoste"
            value={formData.idPoste}
            readOnly
          />
        </div>

        {/* Código Poste - Não Editável */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaCode /> Código Poste
          </label>
          <input
            className={styles.input}
            type="text"
            name="codigoPoste"
            value={formData.codigoPoste}
            readOnly
          />
        </div>

        {/* Status - Não Editável */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaInfoCircle /> Status
          </label>
          <input
            className={styles.input}
            type="text"
            name="status"
            value={
              formData.status === 'D'
                ? 'Desligado'
                : formData.status === 'L'
                ? 'Ligado'
                : 'Desconhecido'
            }
            readOnly
          />
        </div>

        {/* Etiqueta Poste */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaTag /> Etiqueta Poste
          </label>
          <input
            className={styles.input}
            type="text"
            name="etiquetaPoste"
            value={formData.etiquetaPoste}
            readOnly
          />
        </div>
      </div>

      <legend>Localização</legend>
      <div className={styles.formGroup}>
        {/* Geo Localização */}
        <div className={styles.colSpan2}>
          <label className={styles.label}>
            <FaMapMarkerAlt /> Geo Localização
          </label>
          <input
            className={styles.input}
            type="text"
            name="geoLocalizacao"
            value={formData.geoLocalizacao}
            onChange={handleChange}
            required
            placeholder="latitude, longitude"
          />
        </div>

        {/* Botão para obter geolocalização */}
        <div
          className={styles.colSpan1}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <button
            type="button"
            className={styles.button}
            onClick={handleGetUserLocation}
          >
            Obter Geolocalização
          </button>
        </div>

        {/* País */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaGlobe /> País
          </label>
          {isCountriesLoading ? (
            <p>Carregando países...</p>
          ) : countriesError ? (
            <p className={styles.errorText}>{countriesError}</p>
          ) : (
            <select
              className={styles.input}
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um país</option>
              {countries.map((country) => (
                <option key={country.id} value={String(country.id)}>
                  {country.nome_pt || country.nome}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Estado */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaMapSigns /> Estado
          </label>
          {isStatesLoading ? (
            <p>Carregando estados...</p>
          ) : statesError ? (
            <p className={styles.errorText}>{statesError}</p>
          ) : (
            <select
              className={styles.input}
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              required
              disabled={!formData.pais} // Desabilita se nenhum país estiver selecionado
            >
              <option value="">Selecione um estado</option>
              {states.map((state) => (
                <option key={state.id} value={String(state.id)}>
                  {state.nome} ({state.uf})
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Cidade */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaCity /> Cidade
          </label>
          {isCitiesLoading ? (
            <p>Carregando cidades...</p>
          ) : citiesError ? (
            <p className={styles.errorText}>{citiesError}</p>
          ) : (
            <select
              className={styles.input}
              name="cidade"
              value={formData.cidade}
              onChange={handleChange}
              required
              disabled={!formData.estado} // Desabilita se nenhum estado estiver selecionado
            >
              <option value="">Selecione uma cidade</option>
              {cities.map((city) => (
                <option key={city.id} value={String(city.id)}>
                  {city.nome}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Região */}
        <div className={styles.colSpan3}>
          <label className={styles.label}>
            <FaMap /> Região
          </label>
          {isRegiaoLoading ? (
            <p>Carregando regiões...</p>
          ) : regiaoError ? (
            <p className={styles.errorText}>{regiaoError}</p>
          ) : (
            <select
              className={styles.input}
              name="regiao"
              value={formData.regiao}
              onChange={handleChange}
              required
              disabled={!formData.cidade} // Desabilita se nenhuma cidade estiver selecionada
            >
              <option value="">Selecione uma região</option>
              {regioes.map((regiao) => (
                <option key={regiao.regiaoId} value={String(regiao.regiaoId)}>
                  {regiao.regiaoNome}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default IdentificacaoLocalizacao;
