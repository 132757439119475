import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de grupos que são utilizados nos componentes.
 * Apenas grupos onde "pgAtualizaCapaPoste" é "n".
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de grupos, estado de carregamento, erro e função para refetch.
 */
const useGruposComponentes = (isOpen) => {
  const [gruposComponentes, setGruposComponentes] = useState([]);
  const [isGruposComponentesLoading, setIsGruposComponentesLoading] = useState(false);
  const [gruposComponentesError, setGruposComponentesError] = useState(null);

  const fetchGruposComponentes = useCallback(async () => {
    if (!isOpen) return;

    setIsGruposComponentesLoading(true);
    setGruposComponentesError(null);

    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/prodgrupo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar grupos: ${response.statusText}`);
      }

      const data = await response.json();
      // Filtrar grupos onde "pgAtualizaCapaPoste" é "n"
      const gruposFiltrados = data.filter((grupo) => grupo.pgAtualizaCapaPoste === 'n');
      setGruposComponentes(gruposFiltrados);

    } catch (error) {
      console.error('Erro ao buscar grupos:', error);
      setGruposComponentesError('Falha ao carregar a lista de grupos para componentes. Verifique sua conexão ou tente novamente mais tarde.');
    } finally {
      setIsGruposComponentesLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchGruposComponentes();
  }, [fetchGruposComponentes]);

  return { gruposComponentes, isGruposComponentesLoading, gruposComponentesError, refetch: fetchGruposComponentes };
};

export default useGruposComponentes;
