// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import Postes from './pages/Postes/Postes';
import Dashboard from './pages/Dashboard/Dashboard';
import Mapa from './pages/Mapa/Mapa';
import Login from './pages/Login/Login';
import Alertas from './pages/Alertas/Alertas';
import OrdensServicos from './pages/OrdensServicos/OrdensServicos';
import Levantamentos from './pages/Levantamentos/Levantamentos';
import DispositivosIOT from './pages/DispositivosIOT/DispositivosIOT';
import PerfisConfiguracao from './pages/PerfisConfiguracao/PerfisConfiguracao';
import GruposPostes from './pages/GruposPostes/GruposPostes';
import FormPage from './pages/FormPage/FormPage';
import AtrelarDispositivo from './pages/AtrelarDispositivo/AtrelarDispositivo';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'; // Importando ProtectedRoute

// Google Maps API Key
const googleMapsApiKey = 'AIzaSyCV2s1z6eBC30NNKRUvsihZdsKAe6TRFlQ'; // Substitua pela sua chave

function App() {
  return (
    <Router>
      <LoadScript
        googleMapsApiKey={googleMapsApiKey}
        onLoad={() => console.log("Google Maps API loaded successfully")}
        onError={() => console.error("Error loading Google Maps API")}
      >
        <AppInner />
      </LoadScript>
    </Router>
  );
}

function AppInner() {
  const location = useLocation();

  // Verifica se a rota atual corresponde à página de autenticação para esconder o Sidebar
  const isAuthPage = location.pathname === '/login';

  return (
    <div className="App">
      {!isAuthPage && <Sidebar />}
      <div className={`content ${!isAuthPage ? 'withSidebar' : ''}`}>
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Rotas Protegidas */}
          <Route 
            path="/postes" 
            element={
              <ProtectedRoute>
                <Postes />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/alertas" 
            element={
              <ProtectedRoute>
                <Alertas />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/mapa" 
            element={
              <ProtectedRoute>
                <Mapa />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/ordens-servico" 
            element={
              <ProtectedRoute>
                <OrdensServicos />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/levantamentos" 
            element={
              <ProtectedRoute>
                <Levantamentos />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dispositivos" 
            element={
              <ProtectedRoute>
                <DispositivosIOT />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/perfis-configuracao" 
            element={
              <ProtectedRoute>
                <PerfisConfiguracao />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/grupos-postes" 
            element={
              <ProtectedRoute>
                <GruposPostes />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/formulario" 
            element={
              <ProtectedRoute>
                <FormPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/atrelar-dispositivo" 
            element={
              <ProtectedRoute>
                <AtrelarDispositivo />
              </ProtectedRoute>
            } 
          />
          
          {/* Rota Padrão (pode ser uma rota protegida ou pública) */}
          <Route 
            path="/" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
