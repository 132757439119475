import React, { useState, useEffect } from 'react';
import { FaSortUp, FaSortDown, FaPlus } from 'react-icons/fa';
import alertLogs from '../../data/alertLogs.json';
import styles from './Alertas.module.css';
import ServiceOrderModal from './ServiceOrderModal';

const Alertas = () => {
  const [alerts, setAlerts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);

  useEffect(() => {
    setAlerts(alertLogs);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAlerts = () => {
    const sorted = [...alerts];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  };

  const filteredAlerts = sortedAlerts().filter(alert => {
    return (
      alert.idPoste.toString().includes(searchTerm) ||
      alert.tipoAlerta.toLowerCase().includes(searchTerm.toLowerCase()) ||
      alert.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleOpenModal = (alert) => {
    setSelectedAlert(alert);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAlert(null);
  };

  const handleSaveServiceOrder = (serviceOrder) => {
    console.log('Service Order Saved:', serviceOrder);
    setIsModalOpen(false);
    setSelectedAlert(null);
  };

  return (
    <div className={styles.alertListPage}>
      <h1 className={styles.header}>Lista de Alertas</h1>
      <div className={styles.controls}>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Pesquisar por ID do Poste, Tipo de Alerta ou Status..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th onClick={() => requestSort('idAlerta')}>
              ID {sortConfig.key === 'idAlerta' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('idPoste')}>
              ID Poste {sortConfig.key === 'idPoste' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('dispositivoEUI')}>
              Dispositivo EUI {sortConfig.key === 'dispositivoEUI' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('tipoAlerta')}>
              Tipo de Alerta {sortConfig.key === 'tipoAlerta' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('origem')}>
              Origem {sortConfig.key === 'origem' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('data')}>
              Data {sortConfig.key === 'data' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('status')}>
              Status {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('dataResolucao')}>
              Data de Resolução {sortConfig.key === 'dataResolucao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredAlerts.map(alert => (
            <tr key={alert.idAlerta} className={alert.status === 'Não Resolvido' ? styles.alertUnresolved : ''}>
              <td>{alert.idAlerta}</td>
              <td>{alert.idPoste}</td>
              <td>{alert.dispositivoEUI}</td>
              <td>{alert.tipoAlerta}</td>
              <td>{alert.origem}</td>
              <td>{alert.data}</td>
              <td>{alert.status}</td>
              <td>{alert.dataResolucao}</td>
              <td>
                <button className={styles.addButton} onClick={() => handleOpenModal(alert)}>
                  <FaPlus /> Cadastrar Ordem
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <ServiceOrderModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          onSubmit={handleSaveServiceOrder}
          alertData={selectedAlert}
        />
      )}
    </div>
  );
};

export default Alertas;
