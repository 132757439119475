// src/pages/Login/FormularioLogin.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import styles from './Login.module.css';

const FormularioLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cidadeId = 3315; // Fixo para exemplo

    setIsLoading(true); // Ativa o loading

    try {
      const response = await fetch('https://api.ads10.com.br/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuarioUserName: username,
          usuarioSenha: password,
          cidadeId: cidadeId,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data.token;

        // Sucesso no login
        toast.success('Login realizado com sucesso!');

        if (rememberMe) {
          localStorage.setItem('jwtToken', token);
          localStorage.setItem('rememberMe', true);
        } else {
          sessionStorage.setItem('jwtToken', token);
        }

        // Redireciona para o dashboard após o login sem remover o loading
        navigate('/dashboard');
      } else {
        // Exibe mensagem de erro e desativa o loading
        toast.error('Erro no login: ' + (data.message || 'Verifique suas credenciais.'));
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      toast.error('Erro ao conectar-se ao servidor. Tente novamente mais tarde.');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.formWrapper}>
      {/* Exibe o overlay de loading se isLoading for true */}
      {isLoading && <LoadingOverlay />}
      <form onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <FaUser className={styles.icon} /> {/* Ícone de usuário */}
          <input
            type="text"
            className={styles.inputField}
            placeholder="Nome de Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <FaLock className={styles.icon} /> {/* Ícone de senha */}
          <input
            type="password"
            className={styles.inputField}
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.checkboxGroup}>
          <label htmlFor="rememberMe" className={styles.checkboxLabel}>
            <input
              type="checkbox"
              id="rememberMe"
              className={styles.checkbox}
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            Manter conectado
          </label>
        </div>
        <button type="submit" className={styles.button}>Login</button>
      </form>
    </div>
  );
};

export default FormularioLogin;
