// src/pages/DispositivosIOT/DispositivosIOT.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import DeviceModal from '../../components/DeviceModal/DeviceModal'; // Caminho corrigido
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import PageLayout from '../../components/PageLayout/PageLayout';
import styles from './DispositivosIOT.module.css';

const DispositivosIOT = () => {
  // Estados principais
  const [fotocelulas, setFotocelulas] = useState([]);
  const [postefotocelulas, setPostefotocelulas] = useState([]);
  const [postes, setPostes] = useState([]);
  const [fotocelulasComPoste, setFotocelulasComPoste] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [descricaoFilter, setDescricaoFilter] = useState('');
  const [deviceEUIFilter, setDeviceEUIFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const itemsPerPage = 10;

  // Função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDevice(null);
  };

  // Função para buscar dados das fotocélulas
  const fetchFotocelulas = useCallback(async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado. Faça login novamente.');
      }

      const response = await fetch('https://api.ads10.com.br/api/fotocelula', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar fotocélulas: ${response.statusText}`);
      }

      const data = await response.json();
      setFotocelulas(data);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido ao buscar fotocélulas.');
    }
  }, []);

  // Função para buscar o mapeamento poste-fotocélula
  const fetchPostefotocelulas = useCallback(async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado. Faça login novamente.');
      }

      const response = await fetch('https://api.ads10.com.br/api/postefotocelula', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar mapeamento poste-fotocélula: ${response.statusText}`);
      }

      const data = await response.json();
      setPostefotocelulas(data);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido ao buscar mapeamento poste-fotocélula.');
    }
  }, []);

  // Função para buscar dados dos postes
  const fetchPostes = useCallback(async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado. Faça login novamente.');
      }

      const response = await fetch('https://api.ads10.com.br/api/poste', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar postes: ${response.statusText}`);
      }

      const data = await response.json();
      setPostes(data);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido ao buscar postes.');
    }
  }, []);

  // Combinar dados das fotocélulas com os postes
  useEffect(() => {
    if (fotocelulas.length > 0 && postefotocelulas.length > 0 && postes.length > 0) {
      const combinedData = fotocelulas.map(fotocelula => {
        const mapping = postefotocelulas.find(pf => pf.fotocelulaId === fotocelula.fotocelulaId);
        const poste = postes.find(p => p.id.toString() === mapping?.posteId);
        return {
          ...fotocelula,
          posteId: mapping?.posteId || 'N/A',
          codigoPoste: poste?.codigoPoste || 'N/A',
        };
      });
      setFotocelulasComPoste(combinedData);
    }
  }, [fotocelulas, postefotocelulas, postes]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      setError(null); // Resetar o erro antes de uma nova chamada
      try {
        await Promise.all([fetchFotocelulas(), fetchPostefotocelulas(), fetchPostes()]);
      } catch (err) {
        // O erro já está sendo tratado nas funções individuais
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [fetchFotocelulas, fetchPostefotocelulas, fetchPostes]);

  // Função para adicionar novo dispositivo
  const handleAddNew = () => {
    setSelectedDevice(null); // Indica que estamos adicionando um novo dispositivo
    setIsModalOpen(true); // Abre o modal
  };

  // Função para editar dispositivo
  const handleEdit = (device) => {
    setSelectedDevice(device); // Define o dispositivo a ser editado
    setIsModalOpen(true); // Abre o modal
  };

  // Função para deletar dispositivo
  const handleDelete = async (fotocelulaId) => {
    const confirmDelete = window.confirm('Tem certeza que deseja deletar este dispositivo?');
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado. Faça login novamente.');
      }

      const response = await fetch(`https://api.ads10.com.br/api/fotocelula/${fotocelulaId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao deletar dispositivo: ${response.statusText}`);
      }

      // Atualiza a lista de dispositivos removendo o deletado
      setFotocelulas(prevFotocelulas => prevFotocelulas.filter(fc => fc.fotocelulaId !== fotocelulaId));
      // Atualiza a lista combinada
      setFotocelulasComPoste(prev => prev.filter(fc => fc.fotocelulaId !== fotocelulaId));
      alert('Dispositivo deletado com sucesso!');
    } catch (err) {
      console.error(err);
      alert(err.message || 'Ocorreu um erro ao deletar o dispositivo.');
    }
  };

  // Função para salvar um dispositivo (novo ou editado)
  const handleSave = (savedDevice) => {
    if (selectedDevice) {
      // Edição: atualiza o dispositivo existente na lista
      setFotocelulas(prevFotocelulas =>
        prevFotocelulas.map(fc => fc.fotocelulaId === savedDevice.fotocelulaId ? savedDevice : fc)
      );
      // Atualiza a lista combinada
      setFotocelulasComPoste(prevFotocelulas =>
        prevFotocelulas.map(fc => fc.fotocelulaId === savedDevice.fotocelulaId ? { ...savedDevice, codigoPoste: fc.codigoPoste } : fc)
      );
    } else {
      // Adição: adiciona o novo dispositivo à lista
      setFotocelulas(prevFotocelulas => [...prevFotocelulas, savedDevice]);
      // Para obter o mapeamento e o poste, refaça a busca ou atualize manualmente
      // Aqui, assumimos que o backend retorna o novo mapeamento com o poste
      // Você pode ajustar conforme a resposta real do backend
      // Por simplicidade, adicionaremos com 'N/A' para o poste
      setFotocelulasComPoste(prevFotocelulas => [...prevFotocelulas, { ...savedDevice, posteId: 'N/A', codigoPoste: 'N/A' }]);
    }
  };

  // Funções para mudança de filtros
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleDescricaoFilterChange = (e) => {
    setDescricaoFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleDeviceEUIFilterChange = (e) => {
    setDeviceEUIFilter(e.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  // Função para ordenar os dados
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Filtrando os dispositivos com base nos filtros
  const filteredDevices = useMemo(() => {
    return fotocelulasComPoste.filter(device => {
      const matchesSearchTerm = searchTerm
        ? device.fotocelulaId.toString().includes(searchTerm)
        : true;
      const matchesDescricao = descricaoFilter
        ? device.descricao.toLowerCase().includes(descricaoFilter.toLowerCase())
        : true;
      const matchesDeviceEUI = deviceEUIFilter
        ? device.deviceEUI.toLowerCase().includes(deviceEUIFilter.toLowerCase())
        : true;
      const matchesStatus = statusFilter
        ? device.ativo === statusFilter
        : true;

      return matchesSearchTerm && matchesDescricao && matchesDeviceEUI && matchesStatus;
    });
  }, [fotocelulasComPoste, searchTerm, descricaoFilter, deviceEUIFilter, statusFilter]);

  // Ordenando os dispositivos com base na configuração de ordenação
  const sortedDevices = useMemo(() => {
    const sorted = [...filteredDevices];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        const aValue = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
        const bValue = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredDevices, sortConfig]);

  // Paginação
  const pageCount = Math.ceil(sortedDevices.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedDevices.slice(start, end);
  }, [sortedDevices, currentPage, itemsPerPage]);

  // Função para lidar com a mudança de página
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Preparando dados para exportação CSV
  const headers = [
    { label: 'Fotocélula ID', key: 'fotocelulaId' },
    { label: 'Descrição', key: 'descricao' },
    { label: 'Device EUI', key: 'deviceEUI' },
    { label: 'Ativo', key: 'ativo' },
    { label: 'Poste ID', key: 'posteId' },
    { label: 'Código do Poste', key: 'codigoPoste' },
    // Adicione outros headers conforme necessário
  ];

  const csvData = useMemo(() => {
    return sortedDevices.map(device => ({
      fotocelulaId: device.fotocelulaId,
      descricao: device.descricao,
      deviceEUI: device.deviceEUI,
      ativo: device.ativo === 'S' ? 'Sim' : 'Não',
      posteId: device.posteId,
      codigoPoste: device.codigoPoste,
      // Adicione outros campos conforme necessário
    }));
  }, [sortedDevices]);

  return (
    <PageLayout title="Dispositivos IoT">
      {isLoading && <LoadingOverlay />} {/* Tela de carregamento enquanto os dados são carregados */}

      {!isLoading && (
        <div className={styles.container}>
          {/* Exibição de Erro */}
          {error && (
            <ErrorMessage message={error} />
          )}

          {/* Cabeçalho e Botões de Ação */}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Novo Dispositivo
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"dispositivos-iot-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>

          {/* Filtros e Busca */}
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID da Fotocélula..."
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Descrição..."
              value={descricaoFilter}
              onChange={handleDescricaoFilterChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Device EUI..."
              value={deviceEUIFilter}
              onChange={handleDeviceEUIFilterChange}
            />
            <select
              className={`${styles.searchInput} ${styles.selectInput}`}
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">Filtrar por Status...</option>
              <option value="S">Ativo</option>
              <option value="N">Inativo</option>
            </select>
          </div>

          {/* Tabela de Dispositivos */}
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th onClick={() => requestSort('fotocelulaId')}>
                    Fotocélula ID {sortConfig.key === 'fotocelulaId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('descricao')}>
                    Descrição {sortConfig.key === 'descricao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('deviceEUI')}>
                    Device EUI {sortConfig.key === 'deviceEUI' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ativo')}>
                    Ativo {sortConfig.key === 'ativo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('codigoPoste')}>
                    Poste Associado {sortConfig.key === 'codigoPoste' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th>Ações</th> {/* Mantém a coluna de Ações */}
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map(device => (
                    <tr key={device.fotocelulaId} className={styles.tableRow}>
                      <td>{device.fotocelulaId}</td>
                      <td>{device.descricao}</td>
                      <td>{device.deviceEUI}</td>
                      <td>{device.ativo === 'S' ? 'Sim' : 'Não'}</td>
                      <td>{device.codigoPoste}</td>
                      <td>
                        <button className={styles.actionButton} onClick={() => handleEdit(device)}>
                          <FaEdit />
                        </button>
                        <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDelete(device.fotocelulaId)}>
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className={styles.noData}>
                      Nenhum dispositivo encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Componente de Paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              breakLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
              forcePage={currentPage}
            />
          </div>

          {/* Modal de Detalhes do Dispositivo */}
          {isModalOpen && (
            <DeviceModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              deviceData={selectedDevice}
              onSave={handleSave} // Passa a função de callback
            />
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default DispositivosIOT;
