// CompanyLogos.js
import React from 'react';
import styles from './Logos.module.css';
import directLuzLogo from '../../assets/directluz-icone.png'; // Caminho para o logo da DirectLuz
import ceasaLogo from '../../assets/CeasaLogo.png'; // Caminho para o logo da CEASA
import coeLogo from '../../assets/coeLogo.png'; // Caminho para o logo da COE

const Logos = () => {
  return (
    <div className={styles.logoContainer}>
      <img src={directLuzLogo} alt="DirectLuz" className={styles.logo} />
      <img src={coeLogo} alt="COE" className={styles.logo} />
      <img src={ceasaLogo} alt="CEASA" className={styles.logo} />
    </div>
  );
};

export default Logos;
