// src/components/Footer/Footer.jsx

import React from 'react';
import styles from './Footer.module.css';
import { APP_VERSION } from '../../config';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <p>
                DirectLuz &copy; 2024 | Desenvolvido por Vialuz | 
                <span className={styles.version}> Versão {APP_VERSION}</span>
            </p>
        </footer>
    );
};

export default Footer;
