// src/hooks/usePosteDataWithDevices.js
import { useState, useEffect, useCallback } from 'react';

const usePosteDataWithDevices = (token) => {
  const [postes, setPostes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const processPostesData = useCallback((postesData, postefotocelulaData, fotocelulasData) => {
    // Mapeamento de fotocelulaId para deviceEUI
    const fotocelulaIdToDeviceEUI = {};
    fotocelulasData.forEach(fotocelula => {
      fotocelulaIdToDeviceEUI[fotocelula.fotocelulaId] = fotocelula.deviceEUI;
    });

    // Mapeamento de posteId para lista de dispositivos EUI e postefotocelulaId
    const posteIdToDeviceEUIs = {};
    postefotocelulaData.forEach(association => {
      const posteId = association.posteId;
      const fotocelulaId = association.fotocelulaId;
      const deviceEUI = fotocelulaIdToDeviceEUI[fotocelulaId];

      if (deviceEUI && association.ativo === "S") { // Filtra apenas dispositivos ativos
        if (!posteIdToDeviceEUIs[posteId]) {
          posteIdToDeviceEUIs[posteId] = [];
        }
        posteIdToDeviceEUIs[posteId].push({
          deviceEUI,
          postefotocelulaId: association.postefotocelulaId,
        });
      }
    });

    return postesData
      .filter(poste => poste.id !== undefined && poste.id !== null)
      .map(poste => ({
        ...poste,
        euis: posteIdToDeviceEUIs[poste.id] || [],
      }));
  }, []);

  const fetchPostes = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!token) {
        throw new Error('Token não encontrado. Usuário não autenticado.');
      }

      const [postesResponse, postefotocelulaResponse, fotocelulasResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        fetch('https://api.ads10.com.br/api/postefotocelula', {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        fetch('https://api.ads10.com.br/api/fotocelula', {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
      ]);

      if (!postesResponse.ok || !postefotocelulaResponse.ok || !fotocelulasResponse.ok) {
        throw new Error('Erro ao buscar dados das APIs.');
      }

      const [postesData, postefotocelulaData, fotocelulasData] = await Promise.all([
        postesResponse.json(),
        postefotocelulaResponse.json(),
        fotocelulasResponse.json(),
      ]);

      const processedData = processPostesData(postesData, postefotocelulaData, fotocelulasData);
      setPostes(processedData);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido ao buscar dados dos postes.');
    } finally {
      setIsLoading(false);
    }
  }, [processPostesData, token]);

  useEffect(() => {
    fetchPostes();
  }, [fetchPostes]);

  return { postes, isLoading, error, refetch: fetchPostes };
};

export default usePosteDataWithDevices;
