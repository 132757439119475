// src/hooks/useComponentes.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar os componentes.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de componentes, estado de carregamento, erro e função para refetch.
 */
const useComponentes = (isOpen) => {
  const [componentes, setComponentes] = useState([]);
  const [isComponentesLoading, setIsComponentesLoading] = useState(false);
  const [componentesError, setComponentesError] = useState(null);

  const fetchComponentes = useCallback(async () => {
    if (!isOpen) return;

    setIsComponentesLoading(true);
    setComponentesError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/produto', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar componentes: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Componentes recebidos da API:', data);
      setComponentes(data);
    } catch (error) {
      console.error('Erro ao buscar componentes:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setComponentesError('Você precisa estar autenticado para carregar a lista de componentes.');
        alert('Você precisa estar autenticado para carregar a lista de componentes.');
        window.location.href = '/login';
      } else {
        setComponentesError('Falha ao carregar a lista de componentes. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsComponentesLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchComponentes();
  }, [fetchComponentes]);

  return { componentes, isComponentesLoading, componentesError, refetch: fetchComponentes };
};

export default useComponentes;
