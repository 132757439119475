// src/pages/Postes/PosteComponentsModal.jsx

import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ConfirmDeleteLuminariaModal from './ConfirmDeleteLuminariaModal';
import ConfirmDeleteComponentModal from './ConfirmDeleteComponentModal';
import styles from './PosteModal.module.css'; // Usando o mesmo CSS que PosteModal
import Luminarias from './SubComponents/Luminarias';
import Componentes from './SubComponents/Componentes';
import EUIsDevices from './SubComponents/EUIsDevices';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// Importar os hooks customizados
import useGrupos from '../../hooks/useGrupos';
import useGruposComponentes from '../../hooks/useGruposComponentes';
import useComponentes from '../../hooks/useComponentes';

Modal.setAppElement('#root');

const PosteComponentsModal = ({ isOpen, onRequestClose, initialData }) => {
  // Estados para luminárias
  const [savedLuminarias, setSavedLuminarias] = useState([]);
  const [unsavedLuminarias, setUnsavedLuminarias] = useState([]);

  // Estados para dispositivos EUI
  const [savedEUIs, setSavedEUIs] = useState([]);
  const [unsavedEUIs, setUnsavedEUIs] = useState([]);

  // Estados para componentes
  const [savedComponentes, setSavedComponentes] = useState([]);
  const [unsavedComponentes, setUnsavedComponentes] = useState([]);

  // Estados adicionais
  const [isConfirmDeleteLuminariaOpen, setIsConfirmDeleteLuminariaOpen] = useState(false);
  const [isConfirmDeleteComponentOpen, setIsConfirmDeleteComponentOpen] = useState(false);
  const [luminariaToDelete, setLuminariaToDelete] = useState(null);
  const [componentToDelete, setComponentToDelete] = useState(null);

  // Estado para controlar a aba ativa
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Estados para animação dos botões de salvar
  const [animateSaveButtons, setAnimateSaveButtons] = useState({
    euis: {},
    luminarias: {},
    componentes: {},
  });

  // Usar os hooks para obter dados
  const { grupos, isGruposLoading, gruposError, refetch: refetchGrupos } = useGrupos(isOpen);
  const { gruposComponentes, isGruposComponentesLoading, gruposComponentesError, refetch: refetchGruposComponentes } = useGruposComponentes(isOpen);
  const { componentes, isComponentesLoading, componentesError, refetch: refetchComponentes } = useComponentes(isOpen);

  // Filtrar gruposComponentes conforme necessário
  const filteredGruposComponentes = gruposComponentes.filter(grupo => grupo.pgAtualizaCapaPoste === 'n');

  // useEffect para preencher os dados iniciais quando o modal é aberto
  useEffect(() => {
    if (initialData) {
      setSavedLuminarias(initialData.luminarias || []);
      setSavedComponentes(initialData.componentes || []);
      setSavedEUIs(initialData.euis || []);
      setUnsavedLuminarias([]);
      setUnsavedComponentes([]);
      setUnsavedEUIs([]);
    } else {
      setSavedLuminarias([]);
      setSavedComponentes([]);
      setSavedEUIs([]);
      setUnsavedLuminarias([]);
      setUnsavedComponentes([]);
      setUnsavedEUIs([]);
    }
  }, [initialData, isOpen]);

  // Função para disparar animações nos botões de salvar
  const triggerSaveAnimations = () => {
    const euisToAnimate = unsavedEUIs.map((_, index) => index);
    const luminariasToAnimate = unsavedLuminarias.map((_, index) => index);
    const componentesToAnimate = unsavedComponentes.map((_, index) => index);

    setAnimateSaveButtons({
      euis: euisToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
      luminarias: luminariasToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
      componentes: componentesToAnimate.reduce((acc, idx) => ({ ...acc, [idx]: true }), {}),
    });

    // Limpar as animações após a duração
    setTimeout(() => {
      setAnimateSaveButtons({
        euis: {},
        luminarias: {},
        componentes: {},
      });
    }, 500); // Duração da animação
  };

  // Função para lidar com a mudança de aba
  const handleTabSelect = (index) => {
    const hasEUIChanges = unsavedEUIs.length > 0;
    const hasLuminariaChanges = unsavedLuminarias.length > 0;
    const hasComponenteChanges = unsavedComponentes.length > 0;

    if (hasEUIChanges || hasLuminariaChanges || hasComponenteChanges) {
      // Disparar animações nos botões de salvar
      triggerSaveAnimations();

      // Mostrar confirmação depois da animação ser disparada
      setTimeout(() => {
        const confirmSwitch = window.confirm('Você tem mudanças não salvas. Deseja continuar e descartar as mudanças?');
        if (!confirmSwitch) {
          return;
        } else {
          // Limpar luminárias, EUIs e Componentes não salvos
          setUnsavedLuminarias([]);
          setUnsavedEUIs([]);
          setUnsavedComponentes([]);
          setActiveTabIndex(index);
        }
      }, 500); // Tempo para a animação ser percebida antes de exibir o alerta
    } else {
      setActiveTabIndex(index);
    }
  };

  // Função para fechar o modal com verificação de alterações não salvas
  const handleCloseModal = () => {
    const hasEUIChanges = unsavedEUIs.length > 0;
    const hasLuminariaChanges = unsavedLuminarias.length > 0;
    const hasComponenteChanges = unsavedComponentes.length > 0;

    if (hasEUIChanges || hasLuminariaChanges || hasComponenteChanges) {
      // Disparar animações nos botões de salvar
      triggerSaveAnimations();

      // Mostrar confirmação depois da animação ser disparada
      setTimeout(() => {
        const confirmClose = window.confirm('Você tem mudanças não salvas. Deseja fechar sem salvar?');
        if (!confirmClose) {
          return;
        } else {
          // Limpar luminárias, EUIs e Componentes não salvos
          setUnsavedLuminarias([]);
          setUnsavedEUIs([]);
          setUnsavedComponentes([]);
          onRequestClose();
        }
      }, 500); // Tempo para a animação ser percebida antes de exibir o alerta
    } else {
      onRequestClose();
    }
  };

  // Funções para Luminárias
  const handleAddLuminaria = () => {
    if (unsavedLuminarias.length > 0) {
      alert('Você já tem uma luminária não salva. Salve-a ou remova-a antes de adicionar outra.');
      return;
    }

    const now = new Date();

    const formattedDate = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(now);

    const formattedTime = now.toLocaleTimeString('pt-BR', {
      hour12: false,
    });

    const newLuminaria = {
      tipoInstalacao: '',
      grupoId: '',
      grupoNome: '',
      componenteId: '',
      componenteDescricao: '',
      data: formattedDate,
      hora: formattedTime,
    };

    setUnsavedLuminarias(prev => [...prev, newLuminaria]);
  };

  const handleLuminariaChange = (index, e, isSaved = false) => {
    const { name, value } = e.target;
    if (isSaved) {
      setSavedLuminarias(prev => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    } else {
      setUnsavedLuminarias(prev => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    }
  };

  const handleSaveLuminaria = async (index) => {
    const luminaria = unsavedLuminarias[index];
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        alert('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      // Preparar os dados a serem enviados, incluindo o posteId
      const payload = {
        ...luminaria,
        posteId: initialData.idPoste || initialData.id,
      };

      const response = await fetch('https://api.ads10.com.br/api/luminaria', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao salvar luminária: ${response.statusText}`);
      }

      const savedLuminaria = await response.json(); // Supondo que a API retorna a luminária salva com ID

      // Atualizar os estados
      setSavedLuminarias(prev => [...prev, savedLuminaria]);
      setUnsavedLuminarias(prev => prev.filter((_, i) => i !== index));
      alert('Luminária salva com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar luminária:', error);
      alert('Erro ao salvar luminária. Por favor, tente novamente.');
    }
  };

  const handleRemoveLuminaria = (luminaria, isSaved = false) => {
    if (isSaved) {
      setLuminariaToDelete(luminaria);
      setIsConfirmDeleteLuminariaOpen(true);
    } else {
      setUnsavedLuminarias(prev => prev.filter(lum => lum !== luminaria));
    }
  };

  const confirmRemoveLuminaria = async () => {
    if (!luminariaToDelete) return;

    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        alert('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      const response = await fetch(`https://api.ads10.com.br/api/luminaria/${luminariaToDelete.luminariaId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao remover luminária: ${response.statusText}`);
      }

      // Atualizar o estado
      setSavedLuminarias(prev => prev.filter(lum => lum.luminariaId !== luminariaToDelete.luminariaId));
      setIsConfirmDeleteLuminariaOpen(false);
      setLuminariaToDelete(null);
      alert('Luminária removida com sucesso!');
    } catch (error) {
      console.error('Erro ao remover luminária:', error);
      alert('Erro ao remover luminária. Por favor, tente novamente.');
    }
  };

  // Funções para Componentes
  const handleAddComponent = () => {
    if (unsavedComponentes.length > 0) {
      alert('Você já tem um componente não salvo. Salve-o ou remova-o antes de adicionar outro.');
      return;
    }

    const now = new Date();

    const formattedDate = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(now);

    const formattedTime = now.toLocaleTimeString('pt-BR', {
      hour12: false,
    });

    const newComponent = {
      grupoId: '',
      grupoNome: '',
      componenteId: '',
      componenteDescricao: '',
      data: formattedDate,
      hora: formattedTime,
    };

    setUnsavedComponentes(prev => [...prev, newComponent]);
  };

  const handleComponentChange = (index, e, isSaved = false) => {
    const { name, value } = e.target;
    if (isSaved) {
      setSavedComponentes(prev => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    } else {
      setUnsavedComponentes(prev => {
        const updated = [...prev];
        updated[index] = { ...updated[index], [name]: value };
        return updated;
      });
    }
  };

  const handleSaveComponent = async (index) => {
    const componente = unsavedComponentes[index];
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        alert('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      // Preparar os dados a serem enviados, incluindo o posteId
      const payload = {
        ...componente,
        posteId: initialData.idPoste || initialData.id,
      };

      const response = await fetch('https://api.ads10.com.br/api/componente', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao salvar componente: ${response.statusText}`);
      }

      const savedComponente = await response.json(); // Supondo que a API retorna o componente salvo com ID

      // Atualizar os estados
      setSavedComponentes(prev => [...prev, savedComponente]);
      setUnsavedComponentes(prev => prev.filter((_, i) => i !== index));
      alert('Componente salvo com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar componente:', error);
      alert('Erro ao salvar componente. Por favor, tente novamente.');
    }
  };

  const handleRemoveComponent = (componente, isSaved = false) => {
    if (isSaved) {
      setComponentToDelete(componente);
      setIsConfirmDeleteComponentOpen(true);
    } else {
      setUnsavedComponentes(prev => prev.filter(comp => comp !== componente));
    }
  };

  const confirmRemoveComponent = async () => {
    if (!componentToDelete) return;

    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        alert('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      const response = await fetch(`https://api.ads10.com.br/api/componente/${componentToDelete.componenteId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao remover componente: ${response.statusText}`);
      }

      // Atualizar o estado
      setSavedComponentes(prev => prev.filter(comp => comp.componenteId !== componentToDelete.componenteId));
      setIsConfirmDeleteComponentOpen(false);
      setComponentToDelete(null);
      alert('Componente removido com sucesso!');
    } catch (error) {
      console.error('Erro ao remover componente:', error);
      alert('Erro ao remover componente. Por favor, tente novamente.');
    }
  };

  // Funções para Dispositivos EUI
  const handleAddEUI = () => {
    if (unsavedEUIs.length > 0) {
      alert('Você já tem um dispositivo EUI não salvo. Salve-o ou remova-o antes de adicionar outro.');
      return;
    }

    setUnsavedEUIs(prev => [...prev, '']); // Adicionar uma nova EUI não salva
  };

  const handleEUIChange = (index, e) => {
    const updatedUnsavedEUIs = [...unsavedEUIs];
    updatedUnsavedEUIs[index] = e.target.value;
    setUnsavedEUIs(updatedUnsavedEUIs);
  };

  const handleSaveEUI = async (index) => {
    const newEUI = unsavedEUIs[index].trim();
    if (!newEUI) {
      alert('O Device EUI não pode estar vazio.');
      return;
    }

    // Enviar para o servidor (POST)
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        alert('Usuário não autenticado. Redirecionando para a página de login.');
        window.location.href = '/login';
        return;
      }

      // Obter fotocelulaId por deviceEUI
      const fotocelulaResponse = await fetch('https://api.ads10.com.br/api/fotocelula', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (fotocelulaResponse.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!fotocelulaResponse.ok) {
        throw new Error(`Erro ao buscar fotocélulas: ${fotocelulaResponse.statusText}`);
      }

      const fotocelulasData = await fotocelulaResponse.json();
      const fotocelula = fotocelulasData.find(fc => fc.deviceEUI === newEUI);

      if (!fotocelula) {
        alert(`Fotocélula não encontrada para o Device EUI: ${newEUI}`);
        return;
      }

      // Criar nova associação postefotocelula
      const newAssociation = {
        posteId: initialData.idPoste || initialData.id,
        fotocelulaId: fotocelula.fotocelulaId,
        data: new Date().toISOString(),
        usuarioId: "1", // Ajuste conforme necessário
        ativo: "S",
        ligado: "L",
        manutencao: "N",
        tensaoRede: "0",
      };

      const postefotocelulaResponse = await fetch('https://api.ads10.com.br/api/postefotocelula', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newAssociation),
      });

      if (postefotocelulaResponse.status === 401) {
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login';
        return;
      }

      if (!postefotocelulaResponse.ok) {
        throw new Error(`Erro ao adicionar dispositivo EUI: ${postefotocelulaResponse.statusText}`);
      }

      const createdAssociation = await postefotocelulaResponse.json();

      // Atualizar o estado dos dispositivos EUI
      setSavedEUIs(prev => [...prev, { deviceEUI: newEUI, postefotocelulaId: createdAssociation.postefotocelulaId }]);

      // Remover do unsavedEUIs
      const updatedUnsavedEUIs = [...unsavedEUIs];
      updatedUnsavedEUIs.splice(index, 1);
      setUnsavedEUIs(updatedUnsavedEUIs);

      alert('Dispositivo EUI adicionado com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar o dispositivo EUI:', error);
      alert('Erro ao salvar o dispositivo EUI. Por favor, tente novamente.');
    }
  };

  const handleRemoveEUI = async (euiObj) => {
    const isSaved = !!euiObj.postefotocelulaId;

    // Se estiver salvo, solicitar confirmação
    if (isSaved) {
      const confirmDelete = window.confirm(`Tem certeza de que deseja remover o Device EUI: ${euiObj.deviceEUI}?`);
      if (!confirmDelete) return;
    }

    try {
      if (isSaved) {
        const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

        if (!token) {
          alert('Usuário não autenticado. Redirecionando para a página de login.');
          window.location.href = '/login';
          return;
        }

        // Deletar associação postefotocelula
        const response = await fetch(`https://api.ads10.com.br/api/postefotocelula/${euiObj.postefotocelulaId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          alert('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }

        if (!response.ok) {
          throw new Error(`Erro ao remover dispositivo EUI: ${response.statusText}`);
        }

        // Atualizar o estado dos dispositivos EUI
        setSavedEUIs(prev => prev.filter(eui => eui.postefotocelulaId !== euiObj.postefotocelulaId));

        alert('Dispositivo EUI removido com sucesso.');
      } else {
        // Remover EUI não salvo diretamente do unsavedEUIs
        setUnsavedEUIs(prevUnsavedEUIs => prevUnsavedEUIs.filter(eui => eui !== euiObj.deviceEUI));
      }
    } catch (error) {
      console.error('Erro ao remover o dispositivo EUI:', error);
      alert('Erro ao remover o dispositivo EUI. Por favor, tente novamente.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Gerenciar Componentes do Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={handleCloseModal}>
        <FaTimes />
      </button>
      <div className={styles.posteComponentsPage}>
        <h1 className={styles.header}>Gerenciar Componentes do Poste</h1>
        <form>
          <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
            <TabList>
              <Tab>Luminárias</Tab>
              <Tab>Dispositivos EUI</Tab>
              <Tab>Componentes</Tab>
            </TabList>

            <TabPanel>
              {/* Luminárias */}
              <Luminarias
                savedLuminarias={savedLuminarias}
                unsavedLuminarias={unsavedLuminarias}
                handleAddLuminaria={handleAddLuminaria}
                handleLuminariaChange={handleLuminariaChange}
                handleSaveLuminaria={handleSaveLuminaria}
                handleRemoveLuminaria={handleRemoveLuminaria}
                grupos={grupos}
                isGruposLoading={isGruposLoading}
                gruposError={gruposError}
                componentes={componentes}
                isComponentesLoading={isComponentesLoading}
                componentesError={componentesError}
                refetchGrupos={refetchGrupos}
                refetchComponentes={refetchComponentes}
                animateSaveButtons={animateSaveButtons.luminarias}
              />
            </TabPanel>

            <TabPanel>
              {/* Dispositivos EUI */}
              <EUIsDevices
                euis={savedEUIs}
                unsavedEUIs={unsavedEUIs}
                handleAddEUI={handleAddEUI}
                handleEUIChange={handleEUIChange}
                handleSaveEUI={handleSaveEUI}
                handleRemoveEUI={handleRemoveEUI}
                animateSaveButtons={animateSaveButtons.euis}
              />
            </TabPanel>

            <TabPanel>
              {/* Componentes */}
              <Componentes
                savedComponentes={savedComponentes}
                unsavedComponentes={unsavedComponentes}
                handleAddComponent={handleAddComponent}
                handleComponentChange={handleComponentChange}
                handleSaveComponent={handleSaveComponent}
                handleRemoveComponent={handleRemoveComponent}
                grupos={filteredGruposComponentes}
                isGruposLoading={isGruposComponentesLoading}
                gruposError={gruposComponentesError}
                refetchGrupos={refetchGruposComponentes}
                componentes={componentes}
                isComponentesLoading={isComponentesLoading}
                componentesError={componentesError}
                refetchComponentes={refetchComponentes}
                animateSaveButtons={animateSaveButtons.componentes}
              />
            </TabPanel>
          </Tabs>
        </form>

        {/* Modais de confirmação */}
        <ConfirmDeleteLuminariaModal
          isOpen={isConfirmDeleteLuminariaOpen}
          onRequestClose={() => setIsConfirmDeleteLuminariaOpen(false)}
          onConfirmDelete={confirmRemoveLuminaria}
        />

        <ConfirmDeleteComponentModal
          isOpen={isConfirmDeleteComponentOpen}
          onRequestClose={() => setIsConfirmDeleteComponentOpen(false)}
          onConfirmDelete={confirmRemoveComponent}
        />
      </div>
    </Modal>
  );
};

export default PosteComponentsModal;
