import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styles from './ServiceOrderModal.module.css';
import markersData from '../../data/markers.json';

Modal.setAppElement('#root');

const ServiceOrderModal = ({ isOpen, onRequestClose, onSubmit, alertData }) => {
  const [formData, setFormData] = useState({
    idAlerta: alertData.idAlerta,
    idPoste: alertData.idPoste,
    cidade: '',
    regiao: '',
    bairro: '',
    prestador: '',
    descricaoAlerta: alertData.tipoAlerta
  });

  useEffect(() => {
    const marker = markersData.find(marker => marker.idPoste === alertData.idPoste);
    if (marker) {
      setFormData(prevFormData => ({
        ...prevFormData,
        cidade: marker.cidade || '',
        regiao: marker.regiao || '',
        bairro: marker.bairro || ''
      }));
    }
  }, [alertData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Cadastrar Ordem de Serviço"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.serviceOrderPage}>
        <h2 className={styles.header}>Cadastrar Ordem de Serviço</h2>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              <div className={styles.colSpan1}>
                <label className={styles.label}>ID do Alerta:</label>
                <input className={styles.input} type="text" name="idAlerta" value={formData.idAlerta} readOnly />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Poste:</label>
                <input className={styles.input} type="text" name="idPoste" value={formData.idPoste} readOnly />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Cidade:</label>
                <input className={styles.input} type="text" name="cidade" value={formData.cidade} readOnly />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Região:</label>
                <input className={styles.input} type="text" name="regiao" value={formData.regiao} readOnly />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Bairro:</label>
                <input className={styles.input} type="text" name="bairro" value={formData.bairro} readOnly />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Prestador:</label>
                <select className={styles.input} name="prestador" value={formData.prestador} onChange={handleChange}>
                  <option value="">Selecione um prestador</option>
                  <option value="Prestador 1">Prestador 1</option>
                  <option value="Prestador 2">Prestador 2</option>
                  <option value="Prestador 3">Prestador 3</option>
                </select>
              </div>
              <div className={styles.colSpan3}>
                <label className={styles.label}>Descrição do Alerta:</label>
                <input className={styles.input} type="text" name="descricaoAlerta" value={formData.descricaoAlerta} readOnly />
              </div>
            </div>
          </fieldset>
          <button type="submit" className={styles.button}>Cadastrar Ordem de Serviço</button>
        </form>
      </div>
    </Modal>
  );
};

export default ServiceOrderModal;
