// src/pages/Postes/ConfirmDataModal.jsx

import React from 'react';
import Modal from 'react-modal';
import styles from './ConfirmDataModal.module.css';

Modal.setAppElement('#root');

const ConfirmDataModal = ({ isOpen, onRequestClose, formData, selectedImage, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmar Dados"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>Confirme os Dados Antes de Enviar</h2>
      <div className={styles.dataContainer}>
        {Object.entries(formData).map(([key, value]) => (
          <div key={key} className={styles.dataItem}>
            <strong>{key}:</strong> {value}
          </div>
        ))}
        {selectedImage && (
          <div className={styles.imagePreview}>
            <strong>Imagem Selecionada:</strong>
            <img src={selectedImage} alt="Imagem do Poste" />
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={onRequestClose}>
          Cancelar
        </button>
        <button className={styles.confirmButton} onClick={onConfirm}>
          Confirmar e Enviar
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDataModal;
