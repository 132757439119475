import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styles from './EditServiceOrderModal.module.css';

Modal.setAppElement('#root');

const EditServiceOrderModal = ({ isOpen, onRequestClose, order, onSave }) => {
  const [status, setStatus] = useState(order.status);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState(order.comments || []);

  const handleAddComment = () => {
    if (newComment.trim() !== '') {
      setComments([...comments, { text: newComment, date: new Date().toISOString() }]);
      setNewComment('');
    }
  };

  const handleSave = () => {
    onSave({ ...order, status, comments });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Editar Ordem de Serviço"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <h2>Editar Ordem de Serviço</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <div className={styles.formGroup}>
          <label>Status:</label>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="Pendente">Pendente</option>
            <option value="Resolvida">Resolvida</option>
            <option value="Cancelada">Cancelada</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Adicionar Comentário:</label>
          <textarea value={newComment} onChange={(e) => setNewComment(e.target.value)} />
          <button type="button" className={styles.addButton} onClick={handleAddComment}>Adicionar Comentário</button>
        </div>
        <div className={styles.commentsSection}>
          <h3>Comentários</h3>
          {comments.map((comment, index) => (
            <div key={index} className={styles.comment}>
              <p>{comment.text}</p>
              <span>{new Date(comment.date).toLocaleString()}</span>
            </div>
          ))}
        </div>
        <button type="submit" className={styles.button}>Salvar</button>
      </form>
    </Modal>
  );
};

export default EditServiceOrderModal;
