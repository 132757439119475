// src/hooks/useCountries.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de países.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de países, estado de carregamento, erro e função para refetch.
 */
const useCountries = (isOpen) => {
  const [countries, setCountries] = useState([]);
  const [isCountriesLoading, setIsCountriesLoading] = useState(false);
  const [countriesError, setCountriesError] = useState(null);

  /**
   * Função para buscar os países da API.
   */
  const fetchCountries = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsCountriesLoading(true);
    setCountriesError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/pais', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar países: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Países recebidos da API:', data); // Log para depuração
      setCountries(data);
    } catch (error) {
      console.error('Erro ao buscar países:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setCountriesError('Você precisa estar autenticado para carregar a lista de países.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de países.');
        window.location.href = '/login';
      } else {
        setCountriesError('Falha ao carregar a lista de países. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsCountriesLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return { countries, isCountriesLoading, countriesError, refetch: fetchCountries };
};

export default useCountries;
