// src/hooks/useTipoVia.js

import { useState, useEffect, useCallback } from 'react';

/**
 * Hook customizado para buscar a lista de tipos de via.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de tipos de via, estado de carregamento, erro e função para refetch.
 */
const useTipoVia = (isOpen) => {
  const [tiposVia, setTiposVia] = useState([]);
  const [isTipoViaLoading, setIsTipoViaLoading] = useState(false);
  const [tipoViaError, setTipoViaError] = useState(null);

  /**
   * Função para buscar os tipos de via da API.
   */
  const fetchTipoVia = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsTipoViaLoading(true);
    setTipoViaError(null);
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/tipovia', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        // Sessão expirada ou token inválido
        alert('Sessão expirada. Faça login novamente.');
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        window.location.href = '/login'; // Redirecionar para a página de login
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar tipos de via: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Tipos de Via recebidos da API:', data); // Log para depuração
      setTiposVia(data);
    } catch (error) {
      console.error('Erro ao buscar tipos de via:', error);
      if (error.message === 'Token de autenticação não encontrado') {
        setTipoViaError('Você precisa estar autenticado para carregar a lista de tipos de via.');
        // Opcional: redirecionar para a página de login
        alert('Você precisa estar autenticado para carregar a lista de tipos de via.');
        window.location.href = '/login';
      } else {
        setTipoViaError('Falha ao carregar a lista de tipos de via. Verifique sua conexão ou tente novamente mais tarde.');
      }
    } finally {
      setIsTipoViaLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchTipoVia();
  }, [fetchTipoVia]);

  return { tiposVia, isTipoViaLoading, tipoViaError, refetch: fetchTipoVia };
};

export default useTipoVia;
